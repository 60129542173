import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { BodyAccount } from "../../../_styled/dashboard-components";
import {
    getToken,
    emailsRequest,
    groupsRequest,
    subscriptionsRequest
} from '../../../../static/request/production.request';
import Navbar from '../../../_layout/_navbar/NavbarComponent';
import DefaultHeader from '../../../_layout/_headers/DefaultHeader';
import CopyToClipboard from 'react-copy-to-clipboard';
import NetflixBanner from '../../../../assents/banners/netflix.jpeg';
import StarPlusBanner from '../../../../assents/banners/starplus.jpeg';
import DirectvGoBanner from '../../../../assents/banners/directvgo.jpeg';
import LazyLoading from '../../../../components/loading';

export default function SubscriptionsPage() {
    const { id } = useParams();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [selectedButton, setSelectedButton] = useState('');

    /// LOADING
    const [isLoading, setIsLoading] = useState(false);
    const [isMailLoading, setIsMailLoading] = useState(false);

    /// SECTIONS
    const [subscriptionSection, setIsSubscriptionSection] = useState(true);
    const [invoiceSection, setIsInvoiceSection] = useState(false);
    const [mailSection, setIsMailSection] = useState(false);
    const [groupSection, setIsGroupSection] = useState(false);
    const [isInputSubscriptionChanged, setIsInputSubscriptionChanged] = useState(false);
    const [isInputCredentialsChanged, setIsInputCredentialsChanged] = useState(false);

    /// DATA
    const [subscriptionData, setSubscriptionData] = useState({});
    const [emailsData, setEmailsData] = useState({});
    const [viewEmailsData, setViewEmailsData] = useState('');
    const [selectedMailItem, setSelectedMailItem] = useState(null);

    /// STATUS
    const [successRenewSubscription, setSuccessRenewSubscription] = useState('');
    const [errorRenewSubscription, setErrorRenewSubscription] = useState('');

    /// DIRECT INPUTS
    const [newStatus, setNewStatus] = useState('');
    const [isInputRenewChanged, setIsInputRenewChanged] = useState(false);

    /// FORMS SUBSCRIPTION
    const [formSubscription, setFormSubscription] = useState({})
    const [successUpdateSubscription, setSuccessUpdateSubscription] = useState('');
    const [errorUpdateSubscription, setErrorUpdateSubscription] = useState('');
    const setSubscriptionField = (field, value) => {
        setFormSubscription(
            {
                ...formSubscription, [field]: value
            })
    }

    /// FORMS CREDENTIALS
    const [formCredentials, setFormCredentials] = useState('');
    const [successUpdateCredentials, setSuccessUpdateCredentials] = useState('');
    const [errorUpdateCredentials, setErrorUpdateCredentials] = useState('');
    const setCredentialsField = (field, value) => {
        setFormCredentials(
            {
                ...formCredentials, [field]: value
            })
    }


    useEffect(() => {
        const fetchData = async (e) => {
            try {
                if ((selectedButton === 'subscription') || dataLoaded) {
                    const subscriptionResponse = await subscriptionsRequest.get(`/${id}`, {
                        headers: { Authorization: getToken() },
                    });

                    setSubscriptionData(subscriptionResponse.data);
                }

                if (id && selectedButton === 'handleUpdateSubscription') {
                    setSuccessUpdateSubscription(null);
                    setErrorUpdateSubscription(null);

                    const subscriptionResponse = await subscriptionsRequest.put(`/${id}`, formSubscription, {
                        headers: { Authorization: getToken() },
                    });

                    setSuccessUpdateSubscription(subscriptionResponse.data.message);
                    setSelectedButton('subscription');

                    setTimeout(() => {
                        setSuccessUpdateSubscription(null);
                        setIsInputSubscriptionChanged(false);
                    }, 1000);
                }

                if (id && selectedButton === 'handleUpdateStatusSubscription') {
                    const updateParams = { status: newStatus };

                    const subscriptionResponse = await subscriptionsRequest.put(`/${id}`, updateParams, {
                        headers: { Authorization: getToken() },
                    });

                    // setSuccessUpdateSubscription(subscriptionResponse.data.message);
                    setSelectedButton('subscription');
                }

                if (id && selectedButton === 'handleRenewSubscription') {
                    setIsInputRenewChanged(true)
                    setSuccessRenewSubscription('');
                    setErrorRenewSubscription('');

                    const subscriptionResponse = await subscriptionsRequest.post(`/renew/${id}`, {
                        headers: { Authorization: getToken() },
                    });

                    setSuccessRenewSubscription(subscriptionResponse.data.message);
                }

                if (id && selectedButton === 'handleUpdateCredentials') {
                    setSuccessUpdateCredentials(null);
                    setErrorUpdateCredentials(null);

                    const groupResponse = await groupsRequest.put(`/${subscriptionData.credentials?._id}`, formCredentials, {
                        headers: { Authorization: getToken() },
                    });

                    setSuccessUpdateCredentials(groupResponse.data.message);
                    setSelectedButton('subscription');

                    setTimeout(() => {
                        setSuccessUpdateCredentials(null);
                        setIsInputCredentialsChanged(false);
                    }, 1000);
                }

                if (selectedButton === 'viewEmails' && selectedMailItem) {
                    setIsMailLoading(true);

                    const emailsResponse = await emailsRequest.get(`/${id}/${selectedMailItem.uid}`, {
                        headers: { Authorization: getToken() },
                    });

                    setViewEmailsData(emailsResponse.data);
                } else if (selectedButton === 'emails') {
                    setIsLoading(true);

                    const emailsResponse = await emailsRequest.get(`/${id}`, {
                        headers: { Authorization: getToken() },
                    });

                    setEmailsData(emailsResponse.data.emails);
                }

                setDataLoaded(false);

            } catch (error) {

                setErrorRenewSubscription(error.response.data.message);
                setErrorUpdateSubscription(error.response.data.message);
                setErrorUpdateCredentials(error.response.data.message);
                setEmailsData(error.response.data.message);

            } finally {

                setIsLoading(false);
                setIsMailLoading(false);
                setSelectedButton('');

            }
        };

        fetchData();
    }, [id, selectedButton, newStatus, formSubscription, formCredentials, selectedMailItem]);

    const renderLogo = () => {
        const urlData = subscriptionData.subscription?.name;

        if (urlData === 'Play') {
            return <img className="rounded" src={NetflixBanner} alt="Netflix" />;
        } else if (urlData === 'Plus') {
            return <img className="rounded" src={StarPlusBanner} alt="Star Plus" />;
        } else if (urlData === 'Go') {
            return <img className="rounded" src={DirectvGoBanner} alt="DirecTV Go" />;
        }
        return null;
    };

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid">
                            <div className="border-bottom pt-6">
                                <div className="row align-items-center">
                                    <div className="col-sm col-12">
                                        <h1 className="h2 ls-tight">
                                            <span className="d-inline-block me-3">😎</span> {` Servicio de ${subscriptionData.user?._id.account || ''}`}
                                        </h1>
                                    </div>
                                    <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                                        <div className="hstack gap-2 justify-content-sm-end">
                                            <a href={`/services?name=play${'&user_identifier=' + subscriptionData.user?._id._id}`} className="btn btn-sm btn-neutral border-base">
                                                <span className="pe-2">
                                                    <i className="bi bi-people-fill"></i>
                                                </span>
                                                <span>Administrar servicios</span>
                                            </a>
                                            <a href="/home" className="btn btn-sm btn-primary">
                                                <span className="pe-2">
                                                    <i className="bi bi-plus-square-dotted"></i>
                                                </span>
                                                <span>Agregar servicios</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-tabs overflow-x border-0">
                                    <li className="nav-item">
                                        <a
                                            type='button'
                                            className={`nav-link ${subscriptionSection ? 'active' : ''}`}
                                            onClick={() => {
                                                setIsSubscriptionSection(true);
                                                setIsInvoiceSection(false);
                                                setIsMailSection(false);
                                            }}
                                        >
                                            Detalles de acceso
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            type='button'
                                            className={`nav-link ${invoiceSection ? 'active' : ''}`}
                                            onClick={() => {
                                                setIsInvoiceSection(true);
                                                setIsMailSection(false);
                                                setIsSubscriptionSection(false);
                                            }}
                                        >
                                            Información de facturación
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            type='button'
                                            className={`nav-link ${mailSection ? 'active' : ''}`}
                                            onClick={() => {
                                                setIsMailSection(true);
                                                setIsInvoiceSection(false);
                                                setIsSubscriptionSection(false);
                                                setSelectedButton('emails');
                                            }}
                                        >
                                            Bandeja de entrada
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </header>

                    <div className="bg-surface-secondary">
                        {subscriptionData && Object.keys(subscriptionData).length >= 1 ? (
                            <div className="d-xl-flex">

                                <div className="min-w-0 flex-xl-fill">
                                    <main className="py-6">
                                        <div className="container-fluid px-xxl-16">
                                            <div className="vstack gap-6">

                                                {subscriptionSection === true && (
                                                    <>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <form className="mb-6">
                                                                    <div className="row g-5">


                                                                        {/* VISTA DE SUSCRIPCIÓN */}

                                                                        {isInputSubscriptionChanged === false ? (
                                                                            <>
                                                                                {/* VISTA DE SUSCRIPCIÓN */}
                                                                                <div class="col-15  mb-4">
                                                                                    <label class="form-label" >Plataforma</label>
                                                                                    <div class="input-group position-relative" >
                                                                                        <input type="text" class="form-control" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} aria-label="miremoslo.com" aria-describedby="" defaultValue={subscriptionData.subscription?.name} disabled />
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-6">
                                                                                    <div class="">
                                                                                        <label class="form-label" for="email">Proxima fecha de facturación</label>
                                                                                        <div class="input-group position-relative">
                                                                                            <span class="input-group-text" id=""><i class="bi bi-alarm"></i></span>
                                                                                            <input class="form-control" id="renueva" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} defaultValue={subscriptionData.subscription?.renewalDate} disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-6">
                                                                                    <div class="">
                                                                                        <label class="form-label" for="phone_number">Precio</label>
                                                                                        <div class="">
                                                                                            <div class="input-group position-relative">
                                                                                                <span class="input-group-text" id=""><i class="bi bi-cash-stack"></i></span>
                                                                                                <input class="form-control" placeholder="0.00" aria-label="0.00" aria-describedby="" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} defaultValue={subscriptionData.subscription?.price.amount} disabled />
                                                                                                <input class="form-control " placeholder="Moneda" aria-label="0.00" aria-describedby="" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} defaultValue={subscriptionData.subscription?.price.currency} disabled />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="d-flex justify-content-between">
                                                                                    <div className="col-12">
                                                                                        <div class="d-flex align-items-center gap-3">
                                                                                            <a type='button' class="text-muted text-opacity-60 text-opacity-100-hover" style={{ fontSize: '12px', margin: '0px 0px -20px 15px' }} onClick={() => setIsInputSubscriptionChanged(true)}>
                                                                                                <i class="bi bi-pencil me-1"></i> Editar suscripción
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {/* EDITOR DE SUSCRIPCIÓN */}
                                                                                <div class="col-15  mb-4">
                                                                                    <label class="form-label" >Plataforma</label>
                                                                                    <div class="input-group position-relative" >
                                                                                        <input type="text" class="form-control" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} aria-label="miremoslo.com" aria-describedby="" defaultValue={subscriptionData.subscription?.name} onChange={(e) => setSubscriptionField('name', e.target.value)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-6">
                                                                                    <div class="">
                                                                                        <label class="form-label" for="email">Proxima fecha de facturación</label>
                                                                                        <div class="input-group position-relative">
                                                                                            <span class="input-group-text" id=""><i class="bi bi-alarm"></i></span>
                                                                                            <input class="form-control" id="renueva" placeholder='20:00PM' style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} defaultValue={subscriptionData.subscription?.renewalDate} onChange={(e) => setSubscriptionField('renewalDate', e.target.value)} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-6">
                                                                                    <div class="">
                                                                                        <label class="form-label" for="phone_number">Precio</label>
                                                                                        <div class="">
                                                                                            <div class="input-group position-relative">
                                                                                                <span class="input-group-text" id=""><i class="bi bi-cash-stack"></i></span>
                                                                                                <input
                                                                                                    class="form-control"
                                                                                                    placeholder="0.00"
                                                                                                    aria-label="0.00"
                                                                                                    aria-describedby=""
                                                                                                    style={{
                                                                                                        backgroundColor: 'transparent',
                                                                                                        opacity: '1',
                                                                                                        textAlign: 'center',
                                                                                                        fontSize: '15px'
                                                                                                    }}
                                                                                                    defaultValue={subscriptionData.subscription?.price.amount}
                                                                                                    onChange={(e) => setSubscriptionField('price', { amount: e.target.value })}
                                                                                                />
                                                                                                <input class="form-control " placeholder="Moneda" aria-label="0.00" aria-describedby="" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} defaultValue={subscriptionData.subscription?.price.currency} disabled />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {successUpdateSubscription || errorUpdateSubscription ? (
                                                                                    <div>
                                                                                        {successUpdateSubscription && (
                                                                                            <p style={{ color: 'green', fontSize: '12px' }}>
                                                                                                {successUpdateSubscription}
                                                                                            </p>
                                                                                        )}
                                                                                        {errorUpdateSubscription && (
                                                                                            <p style={{ color: 'red', fontSize: '12px' }}>
                                                                                                {errorUpdateSubscription}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null}


                                                                                <div className="col-12">
                                                                                    <div className="card-footer text-end" style={{ fontSize: '12px', margin: '-20px 0px -56px 15px' }}>
                                                                                        <button type="button" className="btn btn-sm btn-neutral me-2" onClick={() => setIsInputSubscriptionChanged(false)}> Volver</button>
                                                                                        <a type='button' className="btn btn-sm btn-primary" onClick={() => { setSelectedButton('handleUpdateSubscription') }}> Guardar </a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}


                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                        <div className="card">
                                                            <div className="card-body">
                                                                <form className="mb-6">
                                                                    <div className="row g-5">


                                                                        {/* VISTA DE CREDENCIALES */}

                                                                        {isInputCredentialsChanged === false ? (
                                                                            <>

                                                                                {/* VISTA DE CREDENCIALES */}

                                                                                <div className="col-12">
                                                                                    <label className="form-label">Identificador</label>
                                                                                    <div className="input-group position-relative" >
                                                                                        <input type="text" className="form-control" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} aria-label="miremoslo.com" aria-describedby="" defaultValue={subscriptionData.credentials?.group.mappsId} disabled />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <label className="form-label" >Mapps User</label>
                                                                                    <div className="input-group position-relative" >
                                                                                        <CopyToClipboard text={`${subscriptionData.credentials?.group.mappsUser}`}>
                                                                                            <button type="button" className="btn btn-sm btn-primary" ><i className="bi bi-clipboard"></i></button>
                                                                                        </CopyToClipboard>
                                                                                        <input type="text" className="form-control" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} aria-label="miremoslo.com" aria-describedby="" defaultValue={subscriptionData.credentials?.group.mappsUser} disabled />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <label className="form-label">Mapps Pass</label>
                                                                                    <div className="input-group position-relative" >
                                                                                        <CopyToClipboard text={`${subscriptionData.credentials?.group.mappsPass}`}>
                                                                                            <button type="button" className="btn btn-sm btn-primary" ><i className="bi bi-clipboard"></i></button>
                                                                                        </CopyToClipboard>
                                                                                        <input type="text" className="form-control" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} aria-label="miremoslo.com" aria-describedby="" defaultValue={subscriptionData.credentials?.group.mappsPass} disabled />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="d-flex justify-content-between">
                                                                                    <div class="d-flex align-items-center gap-3">
                                                                                        <a type='button' class="text-muted text-opacity-60 text-opacity-100-hover" style={{ fontSize: '12px', margin: '0px 0px -20px 15px' }} onClick={() => setIsInputCredentialsChanged(true)}>
                                                                                            <i class="bi bi-gear me-1"></i> Editar credenciales de acceso
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {/* EDITOR DE CREDENCIALES X2*/}

                                                                                <div className="col-12">
                                                                                    <label className="form-label">Identificador</label>
                                                                                    <div className="input-group position-relative" >
                                                                                        <input type="text" className="form-control" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} aria-label="miremoslo.com" aria-describedby="" defaultValue={subscriptionData.credentials?.group.mappsId} onChange={(e) => setCredentialsField('mappsId', e.target.value)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <label className="form-label">Mapps User</label>
                                                                                    <div className="input-group position-relative" >
                                                                                        <CopyToClipboard text={`${subscriptionData.credentials?.group.mappsUser}`}>
                                                                                            <button type="button" className="btn btn-sm btn-primary" ><i className="bi bi-clipboard"></i></button>
                                                                                        </CopyToClipboard>
                                                                                        <input type="text" className="form-control" style={{ backgroundColor: 'transparent', opacity: '1', textAlign: 'center', fontSize: '15px' }} aria-label="miremoslo.com" aria-describedby="" defaultValue={subscriptionData.credentials?.group.mappsUser} onChange={(e) => setCredentialsField('mappsUser', e.target.value)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <label className="form-label">Mapps Pass</label>
                                                                                    <div className="input-group position-relative" >
                                                                                        <CopyToClipboard text={`${subscriptionData.credentials?.group.mappsPass}`}>
                                                                                            <button type="button" className="btn btn-sm btn-primary" ><i className="bi bi-clipboard"></i></button>
                                                                                        </CopyToClipboard>
                                                                                        <input type="text" className="form-control" style={{ textAlign: 'center', fontSize: '15px' }} aria-label="miremoslo.com" aria-describedby="" placeholder="Nueva Contraseña" onChange={(e) => setCredentialsField('mappsPass', e.target.value)} />
                                                                                    </div>
                                                                                </div>

                                                                                {successUpdateCredentials || errorUpdateCredentials ? (
                                                                                    <div>
                                                                                        {successUpdateCredentials && (
                                                                                            <p style={{ color: 'green', fontSize: '12px' }}>
                                                                                                {successUpdateCredentials}
                                                                                            </p>
                                                                                        )}
                                                                                        {errorUpdateCredentials && (
                                                                                            <p style={{ color: 'red', fontSize: '12px' }}>
                                                                                                {errorUpdateCredentials}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null}

                                                                                <div className="col-12">
                                                                                    <div className="card-footer text-end" style={{ fontSize: '12px', margin: '-20px 0px -56px 15px' }}>
                                                                                        <button type="button" className="btn btn-sm btn-neutral me-2" onClick={() => setIsInputCredentialsChanged(false)}> Volver</button>
                                                                                        <a type='button' className="btn btn-sm btn-primary" onClick={() => { setSelectedButton('handleUpdateCredentials') }}> Guardar </a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {invoiceSection === true && (
                                                    <>
                                                        {/* INFORMACION DE FACTURACION */}
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="mb-3" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }}>Información de facturación</div>
                                                                <div className="mb-6" style={{ fontSize: '12px' }}>Las cuotas de membresía se facturan al principio de cada periodo y podrían aparecer en tu cuenta algunos días después de la fecha de facturación. Es probable que se apliquen impuestos a las ventas.</div>

                                                                <div className="list-group list-group-flush list-group-borderless ms-4">
                                                                    {isInputRenewChanged === false && (
                                                                        <>
                                                                            <div className="list-group-item px-2 py-0">
                                                                                <div className="border-start">
                                                                                    <div className="d-flex ms-n6 pb-10">
                                                                                        <div className="flex-none me-3">
                                                                                            <div className="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                                                                                <i className="bi bi-calendar2-day"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small className="d-block mb-1 text-muted">Proxima fecha de facturación</small>
                                                                                            <div>{`${subscriptionData.subscription?.renewalDate || ''}`}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="list-group-item px-2 py-0">
                                                                                <div className="border-start">
                                                                                    <div className="d-flex ms-n6">
                                                                                        <div className="flex-none me-3 pb-0">
                                                                                            <div className="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                                                                                <i className="bi bi-plus-circle"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="flex-fill">
                                                                                            <div className="mt-4">
                                                                                                <a type='button' className="text-sm text-primary" onClick={() => { setSelectedButton('handleRenewSubscription') }}> Renovar suscripción </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {isInputRenewChanged === true && (
                                                                        <>
                                                                            {successRenewSubscription || errorRenewSubscription ? (
                                                                                <div>
                                                                                    {successRenewSubscription && (
                                                                                        <p className="text-success" style={{ fontSize: '12px', padding: '10px' }}>
                                                                                            {successRenewSubscription}
                                                                                        </p>
                                                                                    )}
                                                                                    {errorRenewSubscription && (
                                                                                        <p style={{ color: 'red', fontSize: '12px' }}>
                                                                                            {errorRenewSubscription}
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <p className="text-muted" style={{ fontSize: '12px', padding: '10px' }}>Actualizando...</p>
                                                                            )
                                                                            }
                                                                        </>
                                                                    )}



                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* VER TODAS LAS FACTURAS*/}
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div class="table-responsive">
                                                                    <table class="table table-nowrap table-flush">
                                                                        <thead >
                                                                            <tr>
                                                                                <th scope="col">
                                                                                    <div class="d-flex align-items-center gap-2 ps-1">
                                                                                        <span>Invoice</span>
                                                                                    </div>
                                                                                </th>
                                                                                {/* <th scope="col">Forma de pago</th> */}
                                                                                <th scope="col">Medio</th>
                                                                                <th scope="col">Periodo del servicio</th>
                                                                                <th scope="col">Total</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {subscriptionData.invoices.map((invoice) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="d-flex align-items-center gap-3 ps-1">
                                                                                            <div class="icon icon-shape w-10 h-10 rounded-circle text-sm bg-tertiary bg-opacity-20 text-tertiary">
                                                                                                <i class="bi bi-file-fill"></i>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span class="d-block text-heading font-bold">{invoice.description}</span>
                                                                                                <span class="text-xs text-muted">{invoice.date}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td>{invoice.paymentMethod}</td>
                                                                                    <td>
                                                                                        <span class="badge rounded-pill bg-light text-xs text-success">{invoice.servicePeriod}</span>
                                                                                    </td>
                                                                                    <td>{`${invoice.total.amount} ${invoice.total.currency}`}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="card-footer text-end" style={{ fontSize: '12px', margin: '0px 0px -20px 15px' }}>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-neutral me-2"
                                                                        onClick={() => {
                                                                            setIsMailSection(false);
                                                                            setIsInvoiceSection(false);
                                                                            setIsSubscriptionSection(true);
                                                                        }}
                                                                    >
                                                                        Regresar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {mailSection && (
                                                    <>
                                                        {/* BANDEJA DE ENTRADA */}
                                                        <div style={{ marginTop: '21px', paddingRight: '12px', paddingLeft: '12px' }}>
                                                            <h4 className="mb-5 ">
                                                                <i className="bi bi-app-indicator text-muted me-3"></i>Tus códigos de acceso temporales de Netflix.
                                                            </h4>
                                                            {isLoading ? (
                                                                <LazyLoading /> // Renderizar el componente Loading mientras se carga la información
                                                            ) : (
                                                                <>
                                                                    {Array.isArray(emailsData) && emailsData.length >= 1 ? (
                                                                        <div className="row g-4">
                                                                            {emailsData.map((email) => (
                                                                                <div className="col-md-0" onClick={() => {
                                                                                    setSelectedMailItem(email);
                                                                                    setSelectedButton('viewEmails');
                                                                                }}>
                                                                                    <div className="card">
                                                                                        <div className="p-5">
                                                                                            <div className="row justify-content-between align-items-center" style={{ paddingBottom: '8px' }}>
                                                                                                <div class="d-flex align-items-center justify-content-between gap-6" style={{ position: 'relative', display: 'block', padding: '0.625rem 1.70rem' }}>
                                                                                                    <div class="d-flex align-items-center gap-3">
                                                                                                        <div class="text-primary">
                                                                                                            <span class="d-block text-muted text-sm">{email.fromto} </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="text-end">
                                                                                                        <span class="d-block text-muted text-xs">{email.date}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <a href="#modalViewMail" className="d-inline-block text-sm font-semibold stretched-link" data-bs-toggle="modal">
                                                                                                    <div className="d-flex align-items-center justify-content-between" style={{ position: 'relative', display: 'block', padding: '0.155rem 1.70rem', gap: '4px' }}>
                                                                                                        <div className="d-flex align-items-center" style={{ flex: '9%' }}>
                                                                                                            <div className="text-primary">
                                                                                                                <td className="v1cell v1component-image v1none" align="center" style={{ paddingTop: '0' }}>
                                                                                                                    <img src="https://mail.gnservices.io/mail/program/resources/blocked.gif" width="16" border="0" className="v1inner-radius" style={{ msInterpolationMode: 'bicubic', border: 'none', outline: 'none', borderCollapse: 'collapse', display: 'block', borderRadius: '4px' }} />
                                                                                                                </td>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div style={{ flex: '91%' }}>
                                                                                                            <tr>
                                                                                                                <td align="left" class="v1copy v1p v1none" style={{ fontWeight: '400', fontSize: '16px', lineHeight: '21px', color: '#221f1f', paddingTop: '0' }}>{email.subject}</td>
                                                                                                            </tr>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        <p>No se encuentran correos disponibles en este momento.</p>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <>
                                                            {/* MODAL OFFER INFORMATION */}

                                                            <div className="modal fade" id="modalViewMail" tabindex="-1" aria-labelledby="modalViewMail" style={{ display: "none" }} aria-hidden="true">
                                                                <div class="modal-dialog modal-dialog-centered">
                                                                    {selectedMailItem && (
                                                                        <div className="modal-content shadow-3">
                                                                            <div class="modal-header justify-content-start">
                                                                                <div className="icon icon-shape rounded-3 bg-soft-primary text-primary text-lg me-4">
                                                                                    <i className="bi bi-person-square"></i>
                                                                                </div>
                                                                                <div>
                                                                                    <h1 class="mb-1 h5">{selectedMailItem.subject}</h1>
                                                                                    <small className="d-block text-xs text-muted">From {selectedMailItem.fromto}</small>
                                                                                </div>
                                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                            </div>

                                                                            {isMailLoading ? (
                                                                                <LazyLoading /> // Renderizar el componente Loading mientras se carga la información
                                                                            ) : (
                                                                                <div className=" py-0">
                                                                                    {viewEmailsData && (
                                                                                        <div dangerouslySetInnerHTML={{ __html: viewEmailsData }} />
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    </main>
                                </div>

                                <div className="flex-xl-none w-xl-96 border-start-xl bg-surface-primary h-xl-calc position-sticky top-xl-18">
                                    <aside className="p-8 h-full overflow-y-xl-auto"> <div className="vstack gap-6"> <div> <div className="d-flex align-items-center mb-3"> <h5 className="me-auto">Detalles del servicio</h5> </div> <div className="mb-5">
                                        {renderLogo()}
                                    </div>

                                        <h6 className="text-muted font-semibold mb-3">Identificador</h6>
                                        <div className="d-flex gap-2 flex-wrap justify-content-center">
                                            <a href="#" className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs flex-grow-1" style={{ textAlign: 'center' }}> {`${subscriptionData.credentials?.group.mappsId || ''}`} </a>
                                        </div>
                                    </div>
                                        <div>
                                            <h6 className="text-muted font-semibold mb-3">Información de suscripción</h6>
                                            <div className="list-group list-group-flush list-group-borderless">
                                                <div className="list-group-item px-2 py-0">

                                                    <div class="vstack gap-4">

                                                        <div className="d-flex align-items-center" style={{ marginBottom: '-25px' }}>
                                                            <div className="d-flex pb-6">
                                                                <div className="flex-none me-3">
                                                                    <i className="bi bi-app-indicator text-base text-muted"></i>
                                                                </div>
                                                                <div>
                                                                    <a href="#!" className="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1">Plataforma</a> <div> <small className="text-xs text-muted">Play</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex align-items-center" style={{ marginBottom: '-25px' }}>
                                                            <div className="d-flex pb-6">
                                                                <div className="flex-none me-3">
                                                                    <i className="bi bi-calendar-event text-base text-muted"></i>
                                                                </div>
                                                                <div>
                                                                    <a href="#!" className="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1">Tipo de servicio</a> <div> <small className="text-xs text-muted">Mensual</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex align-items-center" style={{ marginBottom: '-25px' }}>
                                                            <div className="d-flex pb-6">
                                                                <div className="flex-none me-3">
                                                                    <i className="bi bi-cash-stack text-base text-muted"></i>

                                                                </div>
                                                                <div>
                                                                    <a href="#!" className="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1">Costo por mes</a> <div> <small className="text-xs text-muted">{`${subscriptionData.subscription?.price.amount} ${subscriptionData.subscription?.price.currency}`}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex align-items-center" style={{ marginBottom: '-25px' }}>
                                                            <div className="d-flex pb-6">
                                                                <div className="flex-none me-3">
                                                                    <div className={subscriptionData.subscription?.status === 'Activo' ? 'icon icon-shape text-sm w-4 h-4 bg-success rounded-circle' : subscriptionData.subscription?.status === 'Pendiente de renovación' ? 'icon icon-shape text-sm w-4 h-4 bg-primary rounded-circle' : subscriptionData.subscription?.status === 'Retrasada' ? 'icon icon-shape text-sm w-4 h-4 bg-warning rounded-circle' : subscriptionData.subscription?.status === 'Caducada' ? 'icon icon-shape text-sm w-4 h-4 bg-danger rounded-circle' : subscriptionData.subscription?.status === 'Cancelado' ? 'icon icon-shape text-sm w-4 h-4 bg-danger rounded-circle' : ''}></div>
                                                                </div>
                                                                <div>
                                                                    <a type='button' className="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1" data-bs-toggle="dropdown" data-bs-autoclose="outside" aria-expanded="false" style={{ marginTop: '6px' }}> {`Estado ${subscriptionData.subscription?.status}`}</a>

                                                                    <div class="dropdown ms-auto" style={{ marginTop: '10px' }}>
                                                                        <div class="dropdown-menu dropdown-menu-end w-64">

                                                                            <div class="dropdown-header">
                                                                                <small class="d-block" style={{ fontSize: '10px', marginTop: '10px' }}>{`Estado ${subscriptionData.subscription?.status}`}</small>
                                                                                <h6 class="text-sm font-semibold">Type services asignation</h6>
                                                                            </div>

                                                                            <div className="dropdown-item d-flex align-items-center">
                                                                                <div className="flex-none">
                                                                                    <img alt="..." className="avatar avatar-xs rounded-circle" src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg" />
                                                                                </div>
                                                                                <a
                                                                                    type="button"
                                                                                    className="flex-fill ms-3"
                                                                                    data-value="Activo"
                                                                                    onClick={(e) => {
                                                                                        setSelectedButton('handleUpdateStatusSubscription');
                                                                                        setNewStatus(e.currentTarget.getAttribute("data-value"));
                                                                                    }}
                                                                                >
                                                                                    <div style={{ fontWeight: '300', fontSize: '12px' }}>Active Status</div>
                                                                                </a>
                                                                            </div>

                                                                            <div className="dropdown-item d-flex align-items-center">
                                                                                <div className="flex-none">
                                                                                    <img alt="..." className="avatar avatar-xs rounded-circle" src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg" />
                                                                                </div>
                                                                                <a
                                                                                    type="button"
                                                                                    className="flex-fill ms-3"
                                                                                    data-value="Pendiente de renovación"
                                                                                    onClick={(e) => {
                                                                                        setSelectedButton('handleUpdateStatusSubscription');
                                                                                        setNewStatus(e.currentTarget.getAttribute("data-value"));
                                                                                    }}
                                                                                >
                                                                                    <div style={{ fontWeight: '300', fontSize: '12px' }}>Renewals Status</div>
                                                                                </a>
                                                                            </div>

                                                                            <div className="dropdown-item d-flex align-items-center" >
                                                                                <div className="flex-none">
                                                                                    <img alt="..." className="avatar avatar-xs rounded-circle" src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg" />
                                                                                </div>
                                                                                <a
                                                                                    type="button"
                                                                                    className="flex-fill ms-3"
                                                                                    data-value="Retrasada"
                                                                                    onClick={(e) => {
                                                                                        setSelectedButton('handleUpdateStatusSubscription');
                                                                                        setNewStatus(e.currentTarget.getAttribute("data-value"));
                                                                                    }}
                                                                                >
                                                                                    <div style={{ fontWeight: '300', fontSize: '12px' }}>Delayed Status</div>
                                                                                </a>
                                                                            </div>

                                                                            <div className="dropdown-item d-flex align-items-center" >
                                                                                <div className="flex-none">
                                                                                    <img alt="..." className="avatar avatar-xs rounded-circle" src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg" />
                                                                                </div>
                                                                                <a
                                                                                    type="button"
                                                                                    className="flex-fill ms-3"
                                                                                    data-value="Caducada"
                                                                                    onClick={(e) => {
                                                                                        setSelectedButton('handleUpdateStatusSubscription');
                                                                                        setNewStatus(e.currentTarget.getAttribute("data-value"));
                                                                                    }}
                                                                                >
                                                                                    <div style={{ fontWeight: '300', fontSize: '12px' }}>Expired Status</div>
                                                                                </a>
                                                                            </div>

                                                                            <div className="dropdown-item d-flex align-items-center">
                                                                                <div className="flex-none">
                                                                                    <img alt="..." className="avatar avatar-xs rounded-circle" src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg" />
                                                                                </div>
                                                                                <a
                                                                                    type="button"
                                                                                    className="flex-fill ms-3"
                                                                                    data-value="Cancelado"
                                                                                    onClick={(e) => {
                                                                                        setSelectedButton('handleUpdateStatusSubscription');
                                                                                        setNewStatus(e.currentTarget.getAttribute("data-value"));
                                                                                    }}
                                                                                >
                                                                                    <div style={{ fontWeight: '300', fontSize: '12px' }}>Cancelled Status</div>
                                                                                </a>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </aside>
                                </div>

                            </div>
                        ) : (<LazyLoading />)}
                    </div>
                </div>
            </div>
        </BodyAccount>
    )
}
