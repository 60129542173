import axios from "axios";

// Base URL for API requests
const BASE_URL = "https://gateway.mapps.live/v1";
// const BASE_URL = "http://localhost:3000/v1";

// Get access token from localStorage
const getToken = () => {
  const accessToken = JSON.parse(localStorage.getItem("user"))?.accessToken;
  return accessToken;
};

const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: { Authorization: `${getToken()}` }
});

const subscriptionsRequest = axios.create({
  baseURL: BASE_URL + '/subscriptions',
  headers: { Authorization: `${getToken()}` }
});

const groupsRequest = axios.create({
  baseURL: BASE_URL + '/groups',
  headers: { Authorization: `${getToken()}` }
});

const emailsRequest = axios.create({
  baseURL: BASE_URL + '/emails',
  headers: { Authorization: `${getToken()}` }
});

const tokensRequest = axios.create({
  baseURL: BASE_URL + '/tokens',
  headers: { Authorization: `${getToken()}` }
});

const paymentsRequest = axios.create({
  baseURL: BASE_URL + '/payments',
  headers: { Authorization: `${getToken()}` }
});

const offersRequest = axios.create({
  baseURL: BASE_URL + '/offer-activations',
  headers: { Authorization: `${getToken()}` }
});

const pricingRequest = axios.create({
  baseURL: BASE_URL + '/prices',
  headers: { Authorization: `${getToken()}` }
});

const serviceRequest = axios.create({
  baseURL: BASE_URL + '/services/transfer-service',
  headers: { Authorization: `${getToken()}` }
});

const statsRequest = axios.create({
  baseURL: BASE_URL + '/stats',
  headers: { Authorization: `${getToken()}` }
});

const historiesRequest = axios.create({
  baseURL: BASE_URL + '/history',
  headers: { Authorization: `${getToken()}` }
});

const purchasesRequest = axios.create({
  baseURL: BASE_URL + '/stats',
  headers: { Authorization: `${getToken()}` }
});


export {
  publicRequest, // Export the publicRequest axios instance for public API requests
  subscriptionsRequest,
  groupsRequest,
  emailsRequest,
  tokensRequest,
  paymentsRequest,
  offersRequest,
  pricingRequest,
  statsRequest,
  historiesRequest,
  serviceRequest,
  purchasesRequest,
  getToken // Export the getToken function for getting the access token
};

