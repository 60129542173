import React, { useEffect, useState } from 'react'
import { BodyAccount } from "../../_styled/dashboard-components";
import Navbar from '../../_layout/_navbar/NavbarComponent';
import DefaultHeader from '../../_layout/_headers/DefaultHeader';
import { statsRequest } from '../../../static/request/production.request';

export default function UsersPage() {

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user ? user._id : null;

  // Estado para almacenar los datos de pricing y los datos de la plataforma de pricing
  const [stats, setStats] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (stats) => {
    setSelectedItem(stats);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await statsRequest.get();
        setStats(res.data.docs);
      } catch (error) {
        console.log("Error al obtener datos del usuario:", error);
      }
    };

    if (userId) {
      getData();
    }
  }, [userId]);

  return (
    <BodyAccount>
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Navbar />
        <div className="flex-grow-1 h-screen overflow-y-lg-auto">
          <DefaultHeader />

          <header><div class="container-fluid"><div class="border-bottom py-6"><div class="row align-items-center"><div class="col-sm col-12"><h1 class="h2 ls-tight">Roles and Permissions</h1></div><div class="col-sm-auto col-12 mt-4 mt-sm-0"><div class="hstack gap-2 justify-content-sm-end"><a href="#modalExport" class="btn btn-sm btn-neutral border-base" data-bs-toggle="modal"><span class="pe-2"><i class="bi bi-people-fill"></i> </span><span>Share</span> </a><a href="#offcanvasCreate" class="btn btn-sm btn-primary" data-bs-toggle="offcanvas"><span class="pe-2"><i class="bi bi-plus-square-dotted"></i> </span><span>Create</span></a></div></div></div></div></div></header>

          <main class="py-6 bg-surface-secondary">
            <div class="container-fluid">

              {/* MODAL SHARED X CODE */}
              <div class="offcanvas offcanvas-end w-full w-lg-1/3" data-bs-scroll="true" data-bs-backdrop="true" tabindex="-1" id="offcanvasCreate" aria-labelledby="offcanvasCreateLabel">
                <div class="offcanvas-header border-bottom py-5 bg-surface-secondary">
                  <h5 class="offcanvas-title" id="offcanvasCreateLabel">Add a new role</h5>
                  <button type="button" class="btn-close text-reset text-xs" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body vstack gap-5">
                  <div class="row g-5">
                    <div class="col-md-12">
                      <div>
                        <label class="form-label">Role name</label>
                        <input type="text" class="form-control" placeholder="Role name" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div>
                        <label class="form-label">Description</label>
                        <textarea class="form-control" placeholder="Role description ..." rows="2"></textarea>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="form-label">Select permissions</label>
                    <div class="list-group list-group-flush gap-3">
                      <div class="list-group-item p-6 bg-surface-secondary border-0 rounded-2">
                        <div class="d-flex">
                          <div class="w-10">
                            <i class="bi bi-folder-plus text-lg text-muted"></i>
                          </div>
                          <div class="flex-1 me-10">
                            <a href="#" class="d-block h5">Projects</a>
                            <p class="mt-1 text-sm text-muted">Select the permissions you want to add to all the projects in this account.</p>
                          </div>
                        </div>
                        <div class="vstack gap-3 mt-4 ms-10">
                          <div class="d-flex align-items-center">
                            <h6 class="text-sm font-semibold">Can view</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch1" id="switchView1" checked="checked" />
                            </div>
                          </div>
                          <hr class="my-0" />
                          <div class="d-flex align-items-center">
                            <h6 class="font-semibold">Can edit</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch1" id="switchEdit1" checked="checked" />
                            </div>
                          </div>
                          <hr class="my-0" />
                          <div class="d-flex align-items-center">
                            <h6 class="font-semibold">Can publish</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch1" id="switchPublish1" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="list-group-item p-6 bg-surface-secondary border-0 rounded-2">
                        <div class="d-flex">
                          <div class="w-10">
                            <i class="bi bi-folder-plus text-lg text-muted"></i>
                          </div>
                          <div class="flex-1 me-10">
                            <a href="#" class="d-block h5">Users</a>
                            <p class="mt-1 text-sm text-muted">Select the permissions you want to add to all the users in this account.</p>
                          </div>
                        </div>
                        <div class="vstack gap-3 mt-4 ms-10">
                          <div class="d-flex align-items-center">
                            <h6 class="text-sm font-semibold">Can view</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch2" id="switchView2" />
                            </div>
                          </div>
                          <hr class="my-0" />
                          <div class="d-flex align-items-center">
                            <h6 class="font-semibold">Can edit</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch2" id="switchEdit2" checked="checked" />
                            </div>
                          </div>
                          <hr class="my-0" />
                          <div class="d-flex align-items-center">
                            <h6 class="font-semibold">Can publish</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch2" id="switchPublish2" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="list-group-item p-6 bg-surface-secondary border-0 rounded-2">
                        <div class="d-flex">
                          <div class="w-10">
                            <i class="bi bi-folder-plus text-lg text-muted"></i>
                          </div>
                          <div class="flex-1 me-10">
                            <a href="#" class="d-block h5">Payments</a>
                            <p class="mt-1 text-sm text-muted">Select the permissions you want to add to all the projects in this account.</p>
                          </div>
                        </div>
                        <div class="vstack gap-3 mt-4 ms-10">
                          <div class="d-flex align-items-center">
                            <h6 class="text-sm font-semibold">Can view</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch3" id="switchView3" checked="checked" />
                            </div>
                          </div>
                          <hr class="my-0" />
                          <div class="d-flex align-items-center">
                            <h6 class="font-semibold">Can edit</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch3" id="switchEdit3" checked="checked" />
                            </div>
                          </div>
                          <hr class="my-0" />
                          <div class="d-flex align-items-center">
                            <h6 class="font-semibold">Can publish</h6>
                            <div class="form-check form-switch ms-auto">
                              <input class="form-check-input me-n2" type="checkbox" name="switch3" id="switchPublish3" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer py-2 bg-surface-secondary">
                  <button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="offcanvas">Close</button>
                  <button type="button" class="btn btn-sm btn-primary">Save</button>
                </div>
              </div>

              {/* MODAL CREATE */}
              <div class="modal fade" id="modalExport" tabindex="-1" aria-labelledby="modalExport" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content shadow-3">
                    <div class="modal-header">
                      <div class="icon icon-shape rounded-3 bg-soft-primary text-primary text-lg me-4">
                        <i class="bi bi-globe"></i>
                      </div>
                      <div>
                        <h5 class="mb-1">Share to web</h5>
                        <small class="d-block text-xs text-muted">Publish and share link with anyone</small>
                      </div>
                      <div class="ms-auto">
                        <div class="form-check form-switch me-n2">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked="checked" />
                          <label class="form-check-label" for="flexSwitchCheckChecked"></label>
                        </div>
                      </div>
                    </div>
                    <div class="modal-body">
                      <div class="d-flex align-items-center mb-5">
                        <div>
                          <p class="text-sm">Anyone with this link <span class="font-bold text-heading">can view</span>
                          </p>
                        </div>
                        <div class="ms-auto">
                          <a href="#" class="text-sm font-semibold">Settings</a>
                        </div>
                      </div>
                      <div>
                        <div class="input-group input-group-inline">
                          <input type="email" class="form-control" placeholder="username" value="https://webpixels.io/your-amazing-link" />
                          <span class="input-group-text">
                            <i class="bi bi-clipboard"></i>
                          </span>
                        </div>
                        <span class="mt-2 valid-feedback">Looks good!</span>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="me-auto">
                        <a href="#" class="text-sm font-semibold">
                          <i class="bi bi-clipboard me-2"></i>Copy link </a>
                      </div>
                      <button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-sm btn-success">Share file</button>
                    </div>
                  </div>
                </div>
              </div>


              {/* USER INFORMATION */}
              {Object.keys(stats).length >= 1 && (
                <div class="container-fluid">
                  <div class="vstack gap-6">

                    <div class="card">
                      <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                          <h5 class="me-auto">Manage Roles</h5>
                          <div class="dropdown">
                            <a class="text-muted" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="bi bi-three-dots-vertical"></i>
                            </a>
                            <div class="dropdown-menu">
                              <a href="#!" class="dropdown-item">Action </a>
                              <a href="#!" class="dropdown-item">Another action </a>
                              <a href="#!" class="dropdown-item">Something else here</a>
                            </div>
                          </div>
                        </div>
                        <p class="text-sm text-muted mt-1">Manage your account's roles and give your admins only the required permissions.</p>
                      </div>
                      <div class="card-body">

                        <div class="row g-4">

                          {/* .MAP DE STATS */}
                          {stats.map((stats) => (
                            <div class="col-xl-4 col-sm-6" onClick={() => handleItemClick(stats)}>

                              {/* SHORT USER INFORMATION */}
                              <div class="card shadow-none border border-primary-hover">
                                <div class="card-body">
                                  <div class="d-flex align-items-center">
                                    <div>
                                      <div class="hstack gap-4 justify-content-end">
                                        <div class="position-relative text-sm">
                                          <i class="bi bi-person-square text-muted"></i>
                                          {/* <a href="#" class="text-muted text-primary-hover stretched-link "> User</a> */}
                                          <a href="#" class="text-muted text-primary-hover stretched-link "> {stats.user_identifier.isAdmin ? "Administrator" : "User"}
                                          </a>
                                        </div>
                                        <div class="position-relative text-sm">
                                          <i class="bi bi-credit-card text-muted"></i>
                                          <a href="#" class="text-muted text-primary-hover stretched-link"> {`${stats.user_identifier.credit} ${stats.user_identifier.currency}`}</a>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="ms-auto">
                                      <div class="avatar-group">
                                        <a href="#" class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                                          <img alt="..." src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <h6 class="h4 font-semibold mt-5 mb-2">{`${stats.user_identifier.account}`}</h6>
                                  <a href="#modalAddUsers" class="d-inline-block text-sm font-semibold stretched-link" data-bs-toggle="modal">View member -&gt;</a>
                                </div>
                              </div>

                              {/* GO TO SERVICES */}
                              <div class="d-flex justify-content-between">
                                <div class="d-flex align-items-center gap-3">
                                  <a href={`/services?name=play&service_type=monthly_services&user_identifier=${stats.user_identifier._id}`} class="text-muted text-opacity-60 text-opacity-100-hover" style={{ fontSize: '12px', margin: '15px 0px 0px 15px' }}>
                                    <i class="bi bi-back"></i> Go to services
                                  </a>
                                </div>
                              </div>

                            </div>

                          ))}

                          {/* MODAL USER INFORMATION */}
                          {selectedItem && (
                            <div class="modal fade" id="modalAddUsers" tabindex="-1" aria-labelledby="modalAddUsers" style={{ display: "none" }} aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content shadow-3">
                                  <div class="modal-header justify-content-start">
                                    <div class="icon icon-shape rounded-3 bg-soft-primary text-primary text-lg me-4">
                                      <i class="bi bi-person-square"></i>
                                    </div>
                                    <div>
                                      <h5 class="mb-1">Member Information</h5>
                                      <small class="d-block text-xs text-muted">Latest user information</small>
                                    </div>
                                  </div>

                                  <div class="modal-body py-0 scrollable-y" style={{ maxHeight: "425px" }}>
                                    <div class="list-group list-group-flush">


                                      <div style={{ margin: '20px 30px 30px 30px' }}>
                                        <h5>Clever version</h5>
                                        <p class="text-sm text-muted mt-1">Show apps working with:</p>
                                        <div class="vstack gap-3 mt-4">

                                          <div class="form-item-checkable">
                                            <input class="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                            <label class="item w-full" for="filter-version-1">
                                              <div class="flex-fill">
                                                <span class="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2">
                                                  <div class="avatar w-10 h-10 rounded-circle">
                                                    <i class="bi bi-credit-card text-xl me-3"></i>
                                                  </div>
                                                  <div class="flex-fill">
                                                    <span class="text-body font-semibold" style={{ fontSize: '13px' }}>Total Credit</span>
                                                    {/* <span class="d-block text-muted" style={{ fontSize: '11px' }}>250.00 PEN</span> */}
                                                    <span class="d-block text-muted" style={{ fontSize: '11px' }}>{`${selectedItem.balance} ${selectedItem.currency}`}</span>
                                                  </div>
                                                </span>
                                              </div>
                                            </label>
                                          </div>

                                          <div class="form-item-checkable">
                                            <input class="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                            <label class="item w-full" for="filter-version-1">
                                              <div class="flex-fill">
                                                <span class="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2">
                                                  <div class="avatar w-10 h-10 rounded-circle">
                                                    <i class="bi bi-people text-xl me-3"></i>
                                                  </div>
                                                  <div class="flex-fill">
                                                    <span class="text-body font-semibold" style={{ fontSize: '13px' }}>Subscriptions</span>
                                                    {/* <span class="d-block text-muted" style={{ fontSize: '11px' }}>4 Monthly subscriptions</span> */}
                                                    <span class="d-block text-muted" style={{ fontSize: '11px' }}>{`${selectedItem.services} Monthly subscriptions`}</span>

                                                  </div>
                                                </span>
                                              </div>
                                            </label>
                                          </div>

                                          <div class="form-item-checkable">
                                            <input class="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                            <label class="item w-full" for="filter-version-1">
                                              <div class="flex-fill">
                                                <span class="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2">
                                                  <div class="avatar w-10 h-10 rounded-circle">
                                                    <i class="bi bi-clock-history text-xl me-3"></i>
                                                  </div>
                                                  <div class="flex-fill">
                                                    <span class="text-body font-semibold" style={{ fontSize: '13px' }}>Transactions</span>
                                                    {/* <span class="d-block text-muted" style={{ fontSize: '11px' }}>0 Pending to charge</span> */}
                                                    <span class="d-block text-muted" style={{ fontSize: '11px' }}>{selectedItem.transactions === 0 ? 'No charges pending' : `${selectedItem.transactions} Pending to charge`}</span>
                                                  </div>
                                                </span>
                                              </div>
                                            </label>
                                          </div>

                                          <div class="form-item-checkable">
                                            <input class="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                            <label class="item w-full" for="filter-version-1">
                                              <div class="flex-fill">
                                                <span class="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2">
                                                  <div class="avatar w-10 h-10 rounded-circle">
                                                    <i class="bi bi-minecart-loaded text-xl me-3"></i>
                                                  </div>
                                                  <div class="flex-fill">
                                                    <span class="text-body font-semibold" style={{ fontSize: '13px' }}>Monthly Expenses</span>
                                                    {/* <span class="d-block text-muted" style={{ fontSize: '11px' }}>Calculated value for 62 PEN</span> */}
                                                    <span class="d-block text-muted" style={{ fontSize: '11px' }}>{selectedItem.monthly_expenditure === 0 ? `${selectedItem.user_identifier.account} doesn't have monthly expenses` : `Calculated value for ${selectedItem.monthly_expenditure} ${selectedItem.currency}`}</span>
                                                  </div>
                                                </span>
                                              </div>
                                            </label>
                                          </div>

                                        </div>
                                      </div>

                                    </div>
                                    <div style={{ fontSize: '10px', margin: '0px 20px 30px 20px' }}>
                                      <i class="bi bi-box-arrow-in-right me-2"></i>
                                      <span class="">Last accessed 5/23/2020, 10:45 AM</span>
                                    </div>
                                  </div>


                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-sm btn-primary">Go to services</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header d-flex align-items-center">
                        <h5 class="me-auto">Admin Users</h5>
                        <div class="dropdown">
                          <a class="text-muted" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bi bi-three-dots-vertical"></i>
                          </a>
                          <div class="dropdown-menu">
                            <a href="#!" class="dropdown-item">Action </a>
                            <a href="#!" class="dropdown-item">Another action </a>
                            <a href="#!" class="dropdown-item">Something else here</a>
                          </div>
                        </div>
                      </div>
                      <div class="px-4 py-4 border-top border-bottom d-flex flex-column flex-sm-row gap-3">
                        <div class="scrollable-x">
                          <div class="btn-group" style={{ minWidth: "700px" }}>
                            <a href="#" class="btn btn-sm btn-neutral text-primary" aria-current="page">View all</a>
                            <a href="#" class="btn btn-sm btn-neutral">Admin <span class="text-muted text-xs">(5)</span>
                            </a>
                            <a href="#" class="btn btn-sm btn-neutral">Publisher <span class="text-muted text-xs">(3)</span>
                            </a>
                            <a href="#" class="btn btn-sm btn-neutral">Manager <span class="text-muted text-xs">(2)</span>
                            </a>
                          </div>
                        </div>
                        <div class="ms-auto hstack gap-2">
                          <div class="input-group input-group-sm input-group-inline">
                            <span class="input-group-text pe-2">
                              <i class="bi bi-search"></i>
                            </span>
                            <input type="email" class="form-control" placeholder="Search" aria-label="Search" />
                          </div>
                          <div>
                            <button type="button" class="btn btn-sm px-3 btn-neutral d-flex align-items-center">
                              <i class="bi bi-funnel me-2"></i>
                              <span>Filters</span>
                              <span class="vr opacity-20 mx-3"></span>
                              <span class="text-xs text-primary">2</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-hover table-nowrap">
                          <thead class="table-light">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Role</th>
                              <th scope="col">Access</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <img alt="..." src="/img/people/img-1.jpg" class="avatar avatar-sm rounded-circle me-2" />
                                <a class="text-heading text-primary-hover font-semibold" href="#">Robert Fox</a>
                              </td>
                              <td>robert.fox@example.com</td>
                              <td>
                                <span class="badge text-uppercase bg-soft-primary text-primary rounded-pill">Admin</span>
                              </td>
                              <td>
                                <div class="dropdown">
                                  <a href="#" class="font-semibold text-heading text-primary-hover" role="button" data-bs-toggle="dropdown" aria-expanded="false">Full Access <i class="bi bi-chevron-down ms-2 text-xs"></i>
                                  </a>
                                  <ul class="dropdown-menu">
                                    <li>
                                      <a class="dropdown-item" href="#">Full access</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">Can view</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">Can edit</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">Can publish</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                              <td class="text-end">
                                <a href="#" class="btn btn-sm btn-square btn-neutral me-1">
                                  <i class="bi bi-pencil"></i>
                                </a>
                                <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                                  <i class="bi bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img alt="..." src="/img/people/img-2.jpg" class="avatar avatar-sm rounded-circle me-2" />
                                <a class="text-heading text-primary-hover font-semibold" href="#">Darlene Robertson</a>
                              </td>
                              <td>darlene@example.com</td>
                              <td>
                                <span class="badge text-uppercase bg-soft-primary text-primary rounded-pill">Editor</span>
                              </td>
                              <td>
                                <div class="dropdown">
                                  <a href="#" class="font-semibold text-heading text-primary-hover" role="button" data-bs-toggle="dropdown" aria-expanded="false">Can Edit <i class="bi bi-chevron-down ms-2 text-xs"></i>
                                  </a>
                                  <ul class="dropdown-menu">
                                    <li>
                                      <a class="dropdown-item" href="#">Full access</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">Can view</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">Can edit</a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">Can publish</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                              <td class="text-end">
                                <a href="#" class="btn btn-sm btn-square btn-neutral me-1">
                                  <i class="bi bi-pencil"></i>
                                </a>
                                <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                                  <i class="bi bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="card-footer border-0 py-5">
                        <span class="text-muted text-sm">Showing 10 items out of 250 results found</span>
                      </div>
                    </div>

                  </div>
                </div>
              )}



            </div>
          </main>

        </div>
      </div>
    </BodyAccount>
  )
}
