import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { BodyAccount } from '../../_styled/dashboard-components'
import { subscriptionsRequest } from '../../../static/request/production.request';
import Navbar from '../../_layout/_navbar/NavbarComponent';
import DefaultHeader from '../../_layout/_headers/DefaultHeader';
import NetflixIcon from '../../../assents/icons/netflix.jpeg';
import StarPlusIcon from '../../../assents/icons/starplus.jpg';
import DirectvGoIcon from '../../../assents/icons/directvgo.jpg';
import Pagination from '../../../components/pagination';
import HederTitle from '../../_layout/_headers/HederTitle';


export default function ServicePage() {

    const [subscriptionData, setSubscriptionData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isUniqueTypeChanged, setUniqueTypeChanged] = useState(false);
    const userId = JSON.parse(localStorage.getItem("user"))?._id;

    // Obtener la URL base sin parámetros

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentUrl = window.location.href.split('?')[0];

    // Obtener los valores de los parámetros de consulta
    const page = searchParams.get('page');
    const name = searchParams.get('name');
    const user_identifier = searchParams.get('user_identifier');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user_identifier) {
                    const queryParams = {
                        name: capitalizeString(name),
                        page: page || 1
                    };

                    const queryStringParams = new URLSearchParams(queryParams).toString();
                    const response = await subscriptionsRequest.get(`/user/${user_identifier}?${queryStringParams}`);

                    setSubscriptionData(response.data.docs);
                    setTotalPages(response.data.totalPages);
                    setCurrentPage(response.data.currentPage);
                }
            } catch (error) {
                console.error('Error al obtener los datos de suscripción:', error);
            }
        };

        fetchData();
    }, [user_identifier, name, page]);

    const capitalizeString = (str) => {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }
        return '';
    };

    const renderImage = () => {
        const paramName = searchParams.get('name')?.toLowerCase();

        if (paramName === 'play') {
            return <img style={{ width: '100%', borderRadius: '10%' }} src={NetflixIcon} alt="Netflix" />;
        } else if (paramName === 'plus') {
            return <img style={{ width: '100%', borderRadius: '10%' }} src={StarPlusIcon} alt="Star Plus" />;
        } else if (paramName === 'go') {
            return <img style={{ width: '100%', borderRadius: '10%' }} src={DirectvGoIcon} alt="DirecTV Go" />;
        }
        return null;
    };

    if (!userId) {
        return <Redirect replace to="/login" />;
    }

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid"> <div className="border-bottom pt-6"> <HederTitle title='Mis servicios' icon='👾' /> <ul className="nav nav-tabs overflow-x border-0"> <li className="nav-item"> <a href={`${currentUrl}?name=play${'&user_identifier=' + user_identifier}`} className={`nav-link ${capitalizeString(name) === 'Play' ? 'active' : ''}`}> Plataforma Play </a> </li> <li className="nav-item"> <a href={`${currentUrl}?name=plus${'&user_identifier=' + user_identifier}`} className={`nav-link ${capitalizeString(name) === 'Plus' ? 'active' : ''}`}> Plataforma Plus </a> </li> <li className="nav-item"> <a href={`${currentUrl}?name=go${'&user_identifier=' + user_identifier}`} className={`nav-link ${capitalizeString(name) === 'Go' ? 'active' : ''}`}> Plataforma Go </a> </li> </ul> </div> </div>
                    </header>
                    <main className="py-6 bg-surface-secondary">
                        <div className="container-fluid">
                            <div className="vstack gap-6">

                                {/* HERRAMIENTAS DE SELECCIÓN */}
                                <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
                                    <div className="d-flex gap-3">
                                        <div className="input-group input-group-sm input-group-inline">
                                            <span className="input-group-text pe-2">
                                                <i className="bi bi-search"></i>
                                            </span>
                                            <input type="text" className="form-control" placeholder="Search" aria-label="Search" style={{ backgroundColor: 'white', opacity: '1' }} disabled />
                                        </div>
                                        <div>
                                            <a type="button" href='/purchases' className="btn btn-sm px-6 btn-neutral d-flex align-items-center">
                                                <i class="bi bi-shop-window"></i>
                                                <span style={{ marginLeft: '10px' }}>Compras</span>
                                                <span style={{ marginLeft: '5px' }}>Adicionales</span>
                                                <span className="vr opacity-20 mx-4"></span>
                                                <span className="text-xs text-primary">Mostrar</span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="btn-group">
                                        <a type='buttom' className={`btn btn-sm btn-neutral ${isUniqueTypeChanged === false ? 'active text-primary' : ''}`} onClick={() => setUniqueTypeChanged(false)}>Categoria mensual</a>

                                        <a href={`/tokens?name=${name}${'&user_identifier=' + user_identifier}`} type='buttom' className={`btn btn-sm btn-neutral ${isUniqueTypeChanged === true ? 'active text-primary' : ''}`} onClick={() => setUniqueTypeChanged(true)}>Categoria única</a>
                                    </div>
                                </div>


                                {/* SERVICIOS MENSUALES */}
                                {isUniqueTypeChanged === false && subscriptionData.length >= 1 && (
                                    <div className="row g-6"> {subscriptionData.map((subscription) => (<div className="col-xl-3 col-sm-6" key={subscription._id}> <div className="card"> <div className="card-header border-0"> <div className="d-flex justify-content-between align-items-center"> <div> <span className="h6 text-sm font-semibold">
                                        {/* class="icon icon-shape text-sm w-4 h-4 bg-success rounded-circle" */}
                                        <span className={subscription.subscription?.status === 'Proceso' ? 'text-secondary me-2' : subscription.subscription?.status === 'Completado' ? 'text-primary me-2' : subscription.subscription?.status === 'Activo' ? 'text-success me-2' : subscription.subscription?.status === 'Suspender' ? 'text-warning me-2' : subscription.subscription?.status === 'Cancelado' ? 'text-danger me-2' : ''} style={{ fontSize: '20px' }}> ● </span>
                                        {subscription.subscription?.status}</span>

                                    </div> <div className="text-end mx-n2 d-flex align-items-center"> <div className="dropdown"> <a className="text-muted" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="bi bi-three-dots"></i> </a> <div className="dropdown-menu"> <a href={`${currentUrl}/${subscription._id}`} className="dropdown-item">Acceso </a> <a href={`${currentUrl}/${subscription._id}#invoices`} className="dropdown-item">Facturación </a> <a href={`/support`} className="dropdown-item">Darse de baja</a> </div> </div> </div> </div> </div> <div className="card-body text-center"> <figure className="mx-auto"> <div className="w-12 mx-auto"> {renderImage()} </div> </figure> <a href={`${currentUrl}/${subscription._id}`} className="h5 d-block my-6"> {subscription.groupId} </a> <div className="my-4"> <div>

                                        <div style={{ fontSize: '12px' }}> Identificador </div>

                                    </div>
                                        <div className="avatar-group text-primary">

                                            <div style={{ fontSize: '13px' }}> {`${subscription.credentials?.group.mappsId}`} </div>

                                        </div>

                                    </div> <div className="d-flex justify-content-between mt-8 px-4"> <a href={`${currentUrl}/${subscription._id}`} className="px-2 text-muted text-primary-hover"> <i className="bi bi-view-list"></i> </a> <a href={`${currentUrl}/${subscription._id}#invoices`} className="px-2 text-muted text-primary-hover"> <i className="bi bi-wallet"></i> </a> <a href={`/support`} className="px-2 text-muted text-primary-hover"> <i className="bi bi-x-circle"></i> </a> </div> <hr className="my-7" /> <div className="row justify-content-between align-items-center">


                                                <div className="col-12">
                                                    <span className="d-block text-heading mb-0" style={{ fontSize: '13px', fontWeight: '500' }}>Proxima fecha de facturación</span>
                                                    <span className="d-block text-muted" style={{ fontSize: '12px' }}>{`${subscription.subscription?.renewalDate}`}</span>
                                                </div>

                                            </div> </div> </div> </div>))} </div>
                                )}

                                {/* SERVICIOS DE RECUPERACIÓN */}
                                {/* {queryParams.service_type === 'recovery_services' && subscription.length >= 1 && (
                                    <div style={{ marginTop: '21px', paddingRight: '12px', paddingLeft: '12px' }}> <h4 className="mb-5 "> <i className="bi bi-app-indicator text-muted me-3"></i>Recuperación de acceso </h4> <div className="row g-4"> {subscription.map((subscription) => (<div className="col-md-6"> <div className="card"> <div className="p-5"> <div className="row justify-content-between align-items-center"> <div className="col-lg-6 col-12"> <div className="d-flex align-items-center"> <div> {renderLogo()} </div> <div className="ms-4"> <a href={`${subscription.restore_offer}`} target="_blank" rel="noreferrer" className="h6 mb-1" >{`${subscription.name} Recupera`}</a> <span className="d-block text-sm text-muted">Solicitud generada</span> </div> </div> </div> <div className="col-lg-auto col-12 mt-4 mt-lg-0"> <div className="d-flex align-items-center justify-content-between gap-6"> <div> <a href={`${subscription.restore_offer}`} className="text-sm text-muted text-danger-hover font-semibold" target="_blank" rel="noreferrer"> <i className="bi bi-toggles2 text-primary"></i> Restablecer servicio </a> </div> <div className="form-check form-switch me-n2"> <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked="checked" /> </div> </div> </div> </div> </div> </div> </div>))} </div> </div>
                                )} */}

                                {/* NUMERO DE PAGINAS DISPONIBLES */}
                                {isUniqueTypeChanged === false && subscriptionData.length >= 1 && (
                                    <div><Pagination totalPages={totalPages} platform={name} user_identifier={user_identifier} /></div>
                                )}

                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </BodyAccount>
    )
}
