import React, { useState, useEffect } from "react";
import { getToken, historiesRequest, statsRequest } from "../../../static/request/production.request";
import { BodyAccount } from "../../_styled/dashboard-components";
import Navbar from "../../_layout/_navbar/NavbarComponent";
import DefaultHeader from "../../_layout/_headers/DefaultHeader";
import LazyLoading from "../../../components/loading";


export default function DashboardPage() {
    const [statsData, setStatsData] = useState([]);
    const [statsTotalPages, setStatsTotalPages] = useState(0);
    const [statsCurrentPage, setStatsCurrentPage] = useState(1);
    const [selectedStatsItem, setSelectedStatsItem] = useState(null);
    const [historiesData, setHistoriesData] = useState([]);
    const [historiesTotalPages, setHistoriesTotalPages] = useState(0);
    const [historiesCurrentPage, setHistoriesCurrentPage] = useState(1);
    const [selectedHistoriesItem, setSelectedHistoriesItem] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [selectedButton, setSelectedButton] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedButton === 'stats' || dataLoaded) {
                    const statsResponse = await statsRequest.get('', {
                        headers: { Authorization: getToken() },
                        params: { page: statsCurrentPage > 1 ? statsCurrentPage : undefined }
                    });

                    setStatsData(statsResponse.data.docs);
                    setStatsTotalPages(statsResponse.data.totalPages);
                }

                if (selectedButton === 'histories' || dataLoaded) {
                    const historiesResponse = await historiesRequest.get('', {
                        headers: { Authorization: getToken() },
                        params: { page: historiesCurrentPage > 1 ? historiesCurrentPage : undefined }
                    });

                    setHistoriesData(historiesResponse.data.histories);
                    setHistoriesTotalPages(historiesResponse.data.totalPages);
                }

                setDataLoaded(false)
            } catch (error) {
                console.error('Error al obtener datos de estadísticas:', error);
            }
        };

        // Obtener los datos iniciales al cargar la página
        fetchData();
    }, [statsCurrentPage, historiesCurrentPage, selectedButton]);

    const handleStatsButtonClick = (nextPage) => {
        if (nextPage >= 1 && nextPage <= statsTotalPages) {
            setStatsCurrentPage(nextPage);
            setSelectedButton('stats');
        }
    };

    const handleHistoriesButtonClick = (nextPage) => {
        if (nextPage >= 1 && nextPage <= historiesTotalPages) {
            setHistoriesCurrentPage(nextPage);
            setSelectedButton('histories');
        }
    };

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid">
                            <div className="border-bottom py-6">
                                <div className="row align-items-center">
                                    <div className="col-sm col-12">
                                        <h1 className="h2 ls-tight">Roles and Permissions</h1>
                                    </div>
                                    <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                                        <div className="hstack gap-2 justify-content-sm-end">
                                            <a href="#modalExport" className="btn btn-sm btn-neutral border-base" data-bs-toggle="modal">
                                                <span className="pe-2">
                                                    <i className="bi bi-people-fill"></i>
                                                </span>
                                                <span>Share</span>
                                            </a>
                                            <a href="#offcanvasCreate" className="btn btn-sm btn-primary" data-bs-toggle="offcanvas">
                                                <span className="pe-2">
                                                    <i className="bi bi-plus-square-dotted"></i>
                                                </span>
                                                <span>Create</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <main className="py-6 bg-surface-secondary">
                        <div className="container-fluid">

                            {/* USER INFORMATION */}
                            {Object.keys(statsData).length >= 1 && Object.keys(historiesData).length >= 1 ? (
                                <div className="container-fluid">
                                    <div className="vstack gap-6">
                                        <div className="card">
                                            <div className="card-header pb-0">
                                                <div className="d-flex align-items-center">
                                                    <h5 className="me-auto">Manage Roles</h5>
                                                    <div className="dropdown">
                                                        <a className="text-muted" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="bi bi-three-dots-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu">
                                                            <a href="#!" className="dropdown-item">Action </a>
                                                            <a href="#!" className="dropdown-item">Another action </a>
                                                            <a href="#!" className="dropdown-item">Something else here</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-sm text-muted mt-1">Manage your account's roles and give your admins only the required permissions.</p>
                                            </div>
                                            <div className="card-body">
                                                <div className="row g-4">

                                                    {/* STATS INFORMATION */}
                                                    {statsData.map((stats) => (
                                                        <div className="col-xl-4 col-sm-6" onClick={() => setSelectedStatsItem(stats)}>
                                                            <div className="card shadow-none border border-primary-hover">
                                                                <div className="card-body">
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                            <div className="hstack gap-4 justify-content-end">
                                                                                <div className="position-relative text-sm">
                                                                                    <i className="bi bi-person-square text-muted"></i>
                                                                                    <a href="#" className="text-muted text-primary-hover stretched-link "> {stats.user_identifier.isAdmin ? "Administrator" : "User"}
                                                                                    </a>
                                                                                </div>
                                                                                <div className="position-relative text-sm">
                                                                                    <i className="bi bi-credit-card text-muted"></i>
                                                                                    <a href="#" className="text-muted text-primary-hover stretched-link"> {`${stats.user_identifier.credit} ${stats.user_identifier.currency}`}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-auto">
                                                                            <div className="avatar-group">
                                                                                <a href="#" className="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                                                                                    <img alt="..." src={`${stats.user_identifier.profile}`} />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <h6 className="h4 font-semibold mt-5 mb-2">{`${stats.user_identifier.account}`}</h6>
                                                                    <a href="#modalAddUsers" className="d-inline-block text-sm font-semibold stretched-link" data-bs-toggle="modal">View member -&gt;</a>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <a href={`/services?name=play&user_identifier=${stats.user_identifier._id}`} className="text-muted text-opacity-60 text-opacity-100-hover" style={{ fontSize: '12px', margin: '15px 0px 0px 15px' }}>
                                                                        <i className="bi bi-back"></i> Go to services
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    
                                                    <div class="d-flex gap-2 scrollable-x py-3 px-6" style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-neutral rounded-pill px-2 py-1 text-xs shadow-none"
                                                            disabled={statsCurrentPage === 1}
                                                            onClick={() => handleStatsButtonClick(statsCurrentPage - 1)}
                                                        >
                                                            <i className="bi bi-arrow-left-circle-fill opacity-70 me-1"></i>Anterior
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-neutral rounded-pill px-2 py-1 text-xs shadow-none"
                                                            disabled={statsCurrentPage === statsTotalPages}
                                                            onClick={() => handleStatsButtonClick(statsCurrentPage + 1)}
                                                        >
                                                            <i className="bi bi-arrow-right-circle-fill opacity-70 me-1"></i>Siguiente
                                                        </button>
                                                    </div>

                                                    {/* MODAL USER INFORMATION */}

                                                    <div className="modal fade" id="modalAddUsers" tabindex="-1" aria-labelledby="modalAddUsers" style={{ display: "none" }} aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            {selectedStatsItem && (
                                                                <div className="modal-content shadow-3">
                                                                    <div className="modal-header justify-content-start">
                                                                        <div className="icon icon-shape rounded-3 bg-soft-primary text-primary text-lg me-4">
                                                                            <i className="bi bi-person-square"></i>
                                                                        </div>
                                                                        <div>
                                                                            <h5 className="mb-1">Member Information</h5>
                                                                            <small className="d-block text-xs text-muted">Latest user information</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-body py-0 scrollable-y" style={{ maxHeight: "425px" }}>
                                                                        <div className="list-group list-group-flush">
                                                                            <div style={{ margin: '20px 30px 30px 30px' }}>
                                                                                <h5>Clever version</h5>
                                                                                <p className="text-sm text-muted mt-1">Show apps working with:</p>
                                                                                <div className="vstack gap-3 mt-4">
                                                                                    <div className="form-item-checkable">
                                                                                        <input className="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                                                                        <label className="item w-full" for="filter-version-1">
                                                                                            <div className="flex-fill">
                                                                                                <span className="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2">
                                                                                                    <div className="avatar w-10 h-10 rounded-circle">
                                                                                                        <i className="bi bi-credit-card text-xl me-3"></i>
                                                                                                    </div>
                                                                                                    <div className="flex-fill">
                                                                                                        <span className="text-body font-semibold" style={{ fontSize: '13px' }}>Total Credit</span>
                                                                                                        {/* <span className="d-block text-muted" style={{ fontSize: '11px' }}>250.00 PEN</span> */}
                                                                                                        <span className="d-block text-muted" style={{ fontSize: '11px' }}>{`${selectedStatsItem.balance} ${selectedStatsItem.currency}`}</span>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-item-checkable">
                                                                                        <input className="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                                                                        <label className="item w-full" for="filter-version-1">
                                                                                            <div className="flex-fill">
                                                                                                <span className="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2">
                                                                                                    <div className="avatar w-10 h-10 rounded-circle">
                                                                                                        <i className="bi bi-people text-xl me-3"></i>
                                                                                                    </div>
                                                                                                    <div className="flex-fill">
                                                                                                        <span className="text-body font-semibold" style={{ fontSize: '13px' }}>Subscriptions</span>
                                                                                                        {/* <span className="d-block text-muted" style={{ fontSize: '11px' }}>4 Monthly subscriptions</span> */}
                                                                                                        <span className="d-block text-muted" style={{ fontSize: '11px' }}>{`${selectedStatsItem.services} Monthly subscriptions`}</span>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-item-checkable">
                                                                                        <input className="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                                                                        <label className="item w-full" for="filter-version-1">
                                                                                            <div className="flex-fill">
                                                                                                <span className="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2">
                                                                                                    <div className="avatar w-10 h-10 rounded-circle">
                                                                                                        <i className="bi bi-clock-history text-xl me-3"></i>
                                                                                                    </div>
                                                                                                    <div className="flex-fill">
                                                                                                        <span className="text-body font-semibold" style={{ fontSize: '13px' }}>Transactions</span>
                                                                                                        {/* <span className="d-block text-muted" style={{ fontSize: '11px' }}>0 Pending to charge</span> */}
                                                                                                        <span className="d-block text-muted" style={{ fontSize: '11px' }}>{selectedStatsItem.transactions === 0 ? 'No charges pending' : `${selectedStatsItem.transactions} Pending to charge`}</span>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-item-checkable">
                                                                                        <input className="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                                                                        <label className="item w-full" for="filter-version-1">
                                                                                            <div className="flex-fill">
                                                                                                <span className="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2">
                                                                                                    <div className="avatar w-10 h-10 rounded-circle">
                                                                                                        <i className="bi bi-minecart-loaded text-xl me-3"></i>
                                                                                                    </div>
                                                                                                    <div className="flex-fill">
                                                                                                        <span className="text-body font-semibold" style={{ fontSize: '13px' }}>Monthly Expenses</span>
                                                                                                        {/* <span className="d-block text-muted" style={{ fontSize: '11px' }}>Calculated value for 62 PEN</span> */}
                                                                                                        <span className="d-block text-muted" style={{ fontSize: '11px' }}>{selectedStatsItem.monthly_expenditure === 0 ? `${selectedStatsItem.user_identifier.account} doesn't have monthly expenses` : `Calculated value for ${selectedStatsItem.monthly_expenditure} ${selectedStatsItem.currency}`}</span>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ fontSize: '10px', margin: '0px 20px 30px 20px' }}>
                                                                            <i className="bi bi-box-arrow-in-right me-2"></i>
                                                                            <span className="">Last accessed 5/23/2020, 10:45 AM</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-sm btn-neutral" data-bs-dismiss="modal">Close</button>
                                                                        <button type="button" className="btn btn-sm btn-primary">Go to services</button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header d-flex align-items-center">
                                                <h5 class="me-auto">Admin Users</h5>
                                                <div class="dropdown">
                                                    <a class="text-muted" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a href="#!" class="dropdown-item">Action </a>
                                                        <a href="#!" class="dropdown-item">Another action </a>
                                                        <a href="#!" class="dropdown-item">Something else here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="px-4 py-4 border-top border-bottom d-flex flex-column flex-sm-row gap-3">
                                                <div class="scrollable-x">
                                                    <div class="btn-group" style={{ minWidth: "610px" }}>
                                                        <a href="#" class="btn btn-sm btn-neutral text-primary" aria-current="page">View all</a>
                                                        <a href="#" class="btn btn-sm btn-neutral">Subscriptions {/* <span class="text-muted text-xs">(0)</span> */}
                                                        </a>
                                                        <a href="#" class="btn btn-sm btn-neutral">Payments {/* <span class="text-muted text-xs">(0)</span> */}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="ms-auto hstack gap-2">
                                                    <div class="input-group input-group-sm input-group-inline">
                                                        <span class="input-group-text pe-2">
                                                            <i class="bi bi-search"></i>
                                                        </span>
                                                        <input type="email" class="form-control" placeholder="Search" aria-label="Search" />
                                                    </div>
                                                    <div>
                                                        <button type="button" class="btn btn-sm px-3 btn-neutral d-flex align-items-center">
                                                            <i class="bi bi-funnel me-2"></i>
                                                            <span>Filters</span>
                                                            <span class="vr opacity-20 mx-3"></span>
                                                            <span class="text-xs text-primary">2</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table table-hover table-nowrap">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th scope="col">Information</th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {historiesData.map((history) => (
                                                            <tr>
                                                                <td>
                                                                    <div class="d-flex align-items-center gap-3">
                                                                        <div class="icon icon-shape rounded-circle icon-sm flex-none w-10 h-10 text-sm bg-primary bg-opacity-20 text-primary">
                                                                            <i class="bi bi-send-fill"></i>
                                                                        </div>
                                                                        <div class="">
                                                                            <span className="d-block text-heading text-sm font-semibold"> {history.category} </span>
                                                                            <span class="d-block d-sm-block text-muted text-xs">{`${history.date}`}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="me-3">
                                                                            <div class="icon icon-shape rounded-circle icon-sm flex-none w-10 h-10 text-sm bg-primary bg-opacity-20 text-primary avatar">
                                                                                <img alt="..." src={history.user_identifier.profile} style={{ maxWidth: '42px' }} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="flex-fill text-limit">
                                                                            <span class="d-block h6 font-semibold">{history.user_identifier.account}</span>
                                                                            <span class="d-block text-xs text-muted">Priority Access</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    style={{ margin: '0px 20px 0px 20px', textAlign: 'center', fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                    title={history.message}
                                                                >
                                                                    {history.message.length > 52 ? `${history.message.substring(0, 52)}...` : history.message}
                                                                </td>
                                                                <td>
                                                                    <div className="text-end">
                                                                        <span
                                                                            className={`d-block badge bg-light rounded-pill ${history.status === 'Success' ? 'text-success'
                                                                                : history.status === 'Warning' ? 'text-warning'
                                                                                    : history.status === 'Failure' ? 'text-danger'
                                                                                        : ''}`
                                                                            }
                                                                        > {(() => { switch (history.status) { case 'Success': return (<div> <i className="bi bi-check-circle me-1"></i> <span>Éxito</span> </div>); case 'Warning': return (<div> <i className="bi bi-exclamation-circle me-1"></i> <span>Aviso</span> </div>); case 'Failure': return (<div> <i className="bi bi-x-circle me-1"></i> <span>Error</span> </div>); default: return null; } })()} </span>
                                                                    </div>                                                                
                                                                    </td>
                                                                <td class="text-end">
                                                                    <a href="#" class="btn btn-sm btn-square btn-neutral me-1">
                                                                        <i class="bi bi-pencil"></i>
                                                                    </a>
                                                                    <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                                                                        <i class="bi bi-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="card-footer border-0 py-5">
                                                <span class="text-muted text-sm">Showing 10 items out of {historiesTotalPages * 7} results found</span>
                                                <div>
                                                    {/* Aquí va el resto del código de tu página */}
                                                    <div class="d-flex gap-2 scrollable-x py-3 px-6" style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-neutral rounded-pill px-2 py-1 text-xs shadow-none"
                                                            disabled={historiesCurrentPage === 1}
                                                            onClick={() => handleHistoriesButtonClick(historiesCurrentPage - 1)}
                                                        >
                                                            <i className="bi bi-arrow-left-circle-fill opacity-70 me-1"></i>Anterior
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-neutral rounded-pill px-2 py-1 text-xs shadow-none"
                                                            disabled={historiesCurrentPage === historiesTotalPages}
                                                            onClick={() => handleHistoriesButtonClick(historiesCurrentPage + 1)}
                                                        >
                                                            <i className="bi bi-arrow-right-circle-fill opacity-70 me-1"></i>Siguiente
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (<LazyLoading />)}
                        </div>
                    </main>
                </div>
            </div>
        </BodyAccount>
    )
}