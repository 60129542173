import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function Pagination({ totalPages, platform, user_identifier }) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentUrl = window.location.href.split('?')[0];
    const page = searchParams.get('page');
  
    const currentPage = page && !isNaN(parseInt(page)) ? parseInt(page) : 1;

    const generatePageNumbers = () => {
        if (totalPages === 1) {
          return [];
        }
      
        const pageNumbers = [];
      
        if (totalPages === 2) {
          pageNumbers.push(1, 2);
        } else {
          pageNumbers.push(1, 2);
      
          if (totalPages > 4) {
            let startPage = currentPage - 1;
            let endPage = currentPage + 1;
      
            if (startPage < 3) {
              startPage = 3;
              endPage = 5;
            }
      
            if (endPage > totalPages - 2) {
              endPage = totalPages - 2;
              startPage = totalPages - 4;
            }
      
            if (startPage > 3) {
              pageNumbers.push('...');
            }
      
            for (let i = startPage; i <= endPage; i++) {
              pageNumbers.push(i);
            }
      
            if (endPage < totalPages - 2) {
              pageNumbers.push('...');
            }
          } else {
            for (let i = 3; i <= totalPages - 2; i++) {
              pageNumbers.push(i);
            }
          }
      
          pageNumbers.push(totalPages - 1, totalPages);
        }
      
        return pageNumbers;
      };
      


    const getQueryParams = (page) => {
        const queryParams = [];

        if (platform) {
            queryParams.push(`name=${platform}`);
        }

        if (user_identifier) {
            queryParams.push(`user_identifier=${user_identifier}`);
        }

        queryParams.push(`page=${page || currentPage}`);

        return queryParams.join('&');
    };

    const paginationItems = [];

    paginationItems.push(
        <li
            key="prev"
            className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
        >
            <a
                className="page-link"
                href={`${currentUrl}?${getQueryParams(currentPage - 1)}`}
            >
                <i className="bi bi-chevron-left"></i>
            </a>
        </li>
    );

    generatePageNumbers({ currentPage, totalPages }).forEach(
        (pageNumber, index) => {
            if (pageNumber === '...') {
                paginationItems.push(
                    <li key={index} className="page-item">
                        <span className="page-link">...</span>
                    </li>
                );
            } else {
                paginationItems.push(
                    <li
                        key={index}
                        className={`page-item ${currentPage === pageNumber ? 'active' : ''
                            }`}
                    >
                        <a
                            className="page-link"
                            href={`${currentUrl}?${getQueryParams(pageNumber)}`}
                        >
                            {pageNumber}
                        </a>
                    </li>
                );
            }
        }
    );

    paginationItems.push(
        <li
            key="next"
            className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
        >
            <a
                className="page-link"
                href={`${currentUrl}?${getQueryParams(parseInt(currentPage) + 1)}`}
            >
                <i className="bi bi-chevron-right"></i>
            </a>
        </li>
    );

    return (
        <ul className="pagination pagination-sm pagination-spaced gap-2 justify-content-end">
            {paginationItems}
        </ul>
    );
}

export default Pagination;