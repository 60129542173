import styled from "styled-components";

export const BodyAccount = styled.form`
    .table>:not(caption)>*>* { }
`;

export const Welcome = styled.form`
    height: 30px;
    background-color: #410099;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
`;
