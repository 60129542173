
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    BodyLogin,
    ContainerLogin,
    LoginLayaout,
    LoginHeader,
    LogoButton,
    SpaceForLogin,
    ContentSpaceForLogin,
    AuthLayout,
    AuthForm,
    TitleHeading,
    InputContainer,
    FormInput,
    Button,
    ForgotPassword,
    ActionLink,
    TextRegister,
    Footer,
    FooterText,
    TextError
} from "../../_styled/auth-components";
import { login } from "../../../static/redux/_routes"
import { resetError, setError } from '../../../static/redux/userRedux';


export default function AuthPage() {

    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorPrevent, setErrorPrevent] = useState("");
    const { isFetching } = useSelector((state) => state.user);
    const { error } = useSelector((state) => state.user);

    // Restablece el estado de error al actualizar la página
    useEffect(() => {
        const handleBeforeUnload = () => {
            dispatch(resetError()); // Reinicia el estado de error
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Limpia el event listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    const handleClick = async (e) => {
        e.preventDefault();
        // Validar datos de email y password

        try {
            if (email !== "" && password !== "") {
                setErrorPrevent(""); // Establecer la variable a un valor nulo o vacío
                await login(dispatch, { email, password });
            } else {
                setTimeout(() => {
                    setErrorPrevent('Por favor complete todos los campos Email y Password')
                }, 1000); // Puedes ajustar el tiempo de espera según tus necesidades
            }
        } catch (error) {
            // Manejar el error adecuadamente, por ejemplo, actualizar el estado con el error
            dispatch(setError(error)); // Accede a la propiedad "error" del objeto de respuesta de la API

            // Esperar un breve momento antes de reiniciar el estado de error
            setTimeout(() => {
                dispatch(resetError()); // Reiniciar el estado de error después de un breve momento
            }, 20000); // Puedes ajustar el tiempo de espera según tus necesidades
        }
    };


    return (
        <BodyLogin>
            <ContainerLogin>
                <LoginLayaout>
                    <LoginHeader>
                        <LogoButton>
                            <img src="https://miremoslo.net/static/images/MSL-N.png" alt="Miremoslo Logo" id="LogoImage" />
                        </LogoButton>
                    </LoginHeader>
                    <SpaceForLogin>
                        <ContentSpaceForLogin>
                            <AuthLayout>
                                <AuthForm>
                                    <TitleHeading>Acceder</TitleHeading>

                                    <InputContainer className="mb-5">
                                        <FormInput
                                            type="email"
                                            id="email"
                                            className="form-control"
                                            autoComplete="current-email"
                                            placeholder="Email o nombre de usuario"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </InputContainer>
                                    <InputContainer className="mb-5">
                                        <FormInput
                                            type="password"
                                            id="password"
                                            placeholder="Password"
                                            className="form-control"
                                            autoComplete="current-password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </InputContainer>
                                    <Button onClick={handleClick} disabled={isFetching}>
                                        <span className="CallToAction">Acceder</span>
                                    </Button>
                                </AuthForm>
                                <ForgotPassword>
                                    <ActionLink href="/forgot-password" data-t="login-forgot-password-cta">
                                        <span type="semibold" className="ActionLink CallToAction">¿Olvidaste tu contraseña?</span>
                                    </ActionLink>
                                </ForgotPassword>
                                {error && (
                                    <TextError>
                                        {error} {/* Accede a la propiedad "message" del objeto de error */}
                                    </TextError>
                                )}
                                {errorPrevent &&
                                    <TextError>
                                        {errorPrevent} {/* Accede a la propiedad "message" del objeto de error */}
                                    </TextError>
                                }
                            </AuthLayout>
                        </ContentSpaceForLogin>
                    </SpaceForLogin>
                    <Footer>
                        <FooterText>
                            Copyright © 2022 Miremoslo Inc. Todos los derechos reservados.
                        </FooterText>
                    </Footer>
                </LoginLayaout>
            </ContainerLogin>
        </BodyLogin>
    );
};
