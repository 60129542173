import React, { useState, useEffect } from "react";
import { getToken, offersRequest } from '../../../static/request/production.request';
import { BodyAccount } from "../../_styled/dashboard-components";
import { useLocation } from 'react-router-dom';
import Navbar from '../../_layout/_navbar/NavbarComponent';
import NetflixIcon from '../../../assents/icons/netflix.jpeg';
import Pagination from '../../../components/pagination';


export default function ApplicationsPage() {
    const location = useLocation();
    const pathname = location.pathname;
    const params = new URLSearchParams(location.search);
    const serviceType = params.get('service_type');
    const mostRecent = params.get('most_recent');
    const activated = params.get('activated');

    const [offersData, setOffersData] = useState([]);
    const [offersTotalPages, setOffersTotalPages] = useState(0);
    const [offersCurrentPage, setOffersCurrentPage] = useState(1);
    const [selectedOffersItem, setSelectedOffersItem] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [selectedButton, setSelectedButton] = useState('');

    // DELETE MESSAGE STATUS FOR MODAL TYPE
    const [updateMessage, setUpdateMessage] = useState('');
    const [deleteMessage, setDeleteMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                let offersResponse = null;

                if (selectedButton === 'offers' || dataLoaded) {
                    const offersRequestParams = {
                        activated: activated || undefined,
                        most_recent: mostRecent || undefined,
                        service_type: serviceType || undefined,
                        page: offersCurrentPage > 1 ? offersCurrentPage : undefined,
                        limit: '9',
                    };

                    offersResponse = await offersRequest.get('', {
                        headers: { Authorization: getToken() },
                        params: offersRequestParams,
                    });

                    setOffersData(offersResponse.data.docs);
                    setOffersTotalPages(offersResponse.data.totalPages);
                    setOffersCurrentPage(offersResponse.data.currentPages);
                }

                if (selectedButton === 'activatedOffer' && selectedOffersItem) {
                    const updateParams = { activated: true };

                    offersResponse = await offersRequest.put(`/${selectedOffersItem._id}`, updateParams, {
                        headers: { Authorization: getToken() },
                    });

                    setSelectedButton('offers');
                    setUpdateMessage(offersResponse.data.message);
                }

                if (selectedButton === 'deletedOffer' && selectedOffersItem) {
                    offersResponse = await offersRequest.delete(`/${selectedOffersItem._id}`, {
                        headers: { Authorization: getToken() },
                    });

                    setSelectedButton('offers');
                    setDeleteMessage(offersResponse.data.message);
                }

                setDataLoaded(false);
            } catch (error) {
                console.log('Error al obtener datos del usuario:', error);
            }
        };

        fetchData();
    }, [selectedButton, selectedOffersItem]);


    return (
        <BodyAccount>
            <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div class="flex-lg-1 h-screen overflow-y-lg-auto">
                    <header>
                        <div class="container-fluid">
                            <div class="border-bottom pt-6">
                                <div class="row align-items-center">
                                    <div class="col-sm-12 col-12">
                                        <h1 class="h2 ls-tight">Applications</h1>
                                    </div>
                                </div>
                                <ul className="nav nav-tabs overflow-x border-0">
                                    <li className={`nav-item ${serviceType || activated || mostRecent ? 'active' : ''}`}>
                                        <a href={`/applications`} className={`nav-link ${serviceType || activated || mostRecent ? '' : 'active'}`}>View all</a>
                                    </li>
                                    <li className={`nav-item ${mostRecent ? '' : (serviceType || activated ? 'active' : '')}`}>
                                        <a className={`nav-link ${mostRecent ? '' : (serviceType || activated ? 'active' : '')}`}>Popular</a>
                                    </li>
                                    <li className={`nav-item ${mostRecent ? 'active' : ''}`}>
                                        <a href={`${pathname}?${serviceType ? `service_type=${serviceType}&` : ''}${activated ? `activated=${activated}&` : ''}${mostRecent ? `most_recent=${mostRecent}` : '&most_recent=true'}`} className={`nav-link ${mostRecent ? 'active' : ''}`}>Most recent</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </header>
                    <main class="py-6 bg-surface-secondary">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12 col-md-2 vstack gap-3">
                                    <button class="btn btn-sm btn-neutral w-full mb-3 d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-page-filters" aria-expanded="false" aria-controls="collapse-page-filters"> <i class="bi bi-filter-square me-2"></i>Filters </button>
                                    <div class="collapse d-md-block mb-6" id="collapse-page-filters">
                                        <div class="vstack gap-10"> {(serviceType === 'unique_services' || serviceType === 'monthly_services') && (
                                            <div>
                                                <h5>Clever version</h5>
                                                <p class="text-sm text-muted mt-1">Show apps working with:</p>
                                                <div class="vstack gap-3 mt-4">
                                                    <div class="form-item-checkable">
                                                        <input class="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                                        <label class="item w-full" for="filter-version-1">
                                                            <a href={`${pathname}?service_type=${serviceType}&activated=true${mostRecent ? `&most_recent=${mostRecent}` : ''}`} style={{ borderColor: activated === "true" ? "#5c60f5" : "none" }} class="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2"> <i class="bi bi-lightning-charge text-lg me-3"></i> <span class="text-body text-sm font-semibold">Active</span> </a>
                                                        </label>
                                                    </div>
                                                    <div class="form-item-checkable">
                                                        <input class="form-item-check" type="radio" name="filter-version" id="filter-version-1" />
                                                        <a href={`${pathname}?service_type=${serviceType}&activated=false${mostRecent ? `&most_recent=${mostRecent}` : ''}`} className={`form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2${activated === "false" ? ' disabled' : ''}`} style={{ pointerEvents: activated === "false" ? 'none' : 'auto', borderColor: activated === "false" ? "#5c60f5" : "none" }}> <i className="bi bi-cloud-lightning-rain text-lg me-3"></i> <span className="text-body text-sm font-semibold">Inactive</span> </a>
                                                    </div>
                                                </div>
                                            </div>)}
                                            <div>
                                                <h5>Categories</h5>
                                                <p class="text-sm text-muted mt-1">Show apps working with:</p>
                                                <div class="vstack gap-3 mt-4">
                                                    <div class="form-item-checkable">
                                                        <input class="form-item-check" type="radio" name="filter-category" id="filter-category-1" />
                                                        <label class="item w-full" for="filter-category-1">
                                                            <a href={`${pathname}?service_type=unique_services${activated ? `&activated=${activated}` : ''}${mostRecent ? `&most_recent=${mostRecent}` : ''}`} style={{ borderColor: serviceType === "unique_services" ? "#5c60f5" : "none", }} class="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2"> <i class="bi bi-bar-chart-line text-lg me-3"></i> <span class="text-body text-sm font-semibold">Unique</span> </a>
                                                        </label>
                                                    </div>
                                                    <div class="form-item-checkable">
                                                        <input class="form-item-check" type="radio" name="filter-category" id="filter-category-2" />
                                                        <label class="item w-full" for="filter-category-2">
                                                            <a href={`${pathname}?service_type=monthly_services${activated ? `&activated=${activated}` : ''}${mostRecent ? `&most_recent=${mostRecent}` : ''}`} style={{ borderColor: serviceType === "monthly_services" ? "#5c60f5" : "none", }} class="form-item-click d-flex align-items-center border border-primary-hover text-heading p-3 rounded-2"> <i class="bi bi-body-text text-xl me-3"></i> <span class="text-body text-sm font-semibold">Monthly</span> </a>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-9">
                                    <div class="d-flex flex-column flex-sm-row justify-content-between gap-3">
                                        <div class="input-group input-group-sm input-group-inline">
                                            <span class="input-group-text pe-2"> <i class="bi bi-search"></i> </span>
                                            <input type="text" class="form-control" placeholder="Search" aria-label="Search" />
                                        </div>
                                        <a href="#" class="btn btn-sm btn-warning text-nowrap">Submit app</a>
                                    </div>
                                    <div>
                                        {deleteMessage && (<div className="text-success" style={{ fontSize: '10px', marginTop: '10px' }}> {deleteMessage} </div>)}
                                    </div>

                                    {/* OFERTAS DE ACTIVACIÓN */}
                                    {Object.keys(offersData).length >= 1 && (
                                        <div>
                                            <div class="row g-3 g-xl-5 mt-1" key={offersData._id}>
                                                {offersData.map((offers) => (
                                                    <div
                                                        class="col-lg-4 col-sm-6"
                                                        onClick={() => setSelectedOffersItem(offers)} // Utiliza spread operator para clonar el objeto offers
                                                    >
                                                        <div class="card shadow-4-hover">
                                                            <div class="card-body pb-5">
                                                                <div class="d-flex justify-content-between align-items-center">
                                                                    <div>
                                                                        <img
                                                                            style={{ width: "100%", borderRadius: "10%" }}
                                                                            class="w-10 h-10"
                                                                            src={NetflixIcon}
                                                                        />
                                                                    </div>
                                                                    <div class="text-end">
                                                                        {" "}
                                                                        {offers.activated === false && (
                                                                            <button
                                                                                type="button"
                                                                                class="btn btn-sm btn-neutral rounded-pill"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#modalInstallApp"
                                                                                onClick={() => {
                                                                                    setUpdateMessage('');
                                                                                    setDeleteMessage('');
                                                                                }}>
                                                                                <i class="bi bi-plus-lg me-1"></i>
                                                                                <span>Install</span>
                                                                            </button>
                                                                        )}{" "}
                                                                        {offers.activated === true && (
                                                                            <div class="text-end mx-n2 d-flex align-items-center">
                                                                                <div class="d-flex align-items-center text-success text-sm font-semibold">
                                                                                    <i class="bi bi-check-circle-fill me-1"></i>
                                                                                    <span>Installed</span>
                                                                                </div>
                                                                                <div class="dropdown px-2 text-muted">
                                                                                    <a
                                                                                        class="text-muted"
                                                                                        href="#"
                                                                                        role="button"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <i class="bi bi-three-dots"></i>
                                                                                    </a>
                                                                                    <div class="dropdown-menu">
                                                                                        <a
                                                                                            // key={offers._id}
                                                                                            type="button"
                                                                                            class={`dropdown-item ${selectedOffersItem && selectedOffersItem._id === offers._id ? 'selected' : ''}`}
                                                                                            onClick={() => {
                                                                                                setUpdateMessage('');
                                                                                                setDeleteMessage('');
                                                                                                setSelectedButton('deletedOffer');
                                                                                                setSelectedOffersItem(offers);
                                                                                            }}
                                                                                        >
                                                                                            Delete Offer
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}{" "}
                                                                    </div>
                                                                </div>
                                                                <div class="mt-6">
                                                                    {" "}
                                                                    {offers.service_type === "unique_services" && (
                                                                        <div>
                                                                            <h6 class="mb-2">Play S1</h6>
                                                                            <p class="text-sm text-muted">
                                                                                Activation offer created for unique services
                                                                            </p>
                                                                        </div>
                                                                    )}{" "}
                                                                    {offers.service_type === "monthly_services" && (
                                                                        <div>
                                                                            <h6 class="mb-2">Play S2</h6>
                                                                            <p class="text-sm text-muted">
                                                                                Activation offer created for monthly services
                                                                            </p>
                                                                        </div>
                                                                    )}{" "}
                                                                </div>
                                                                <div class="dropdown ms-auto" style={{ marginTop: "10px" }}>
                                                                    <a
                                                                        href="#"
                                                                        class="text-muted text-primary-hover"
                                                                        data-bs-toggle="dropdown"
                                                                        data-bs-autoclose="outside"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i class="bi bi-gear"></i>
                                                                    </a>
                                                                    <div class="dropdown-menu dropdown-menu-end w-64">
                                                                        <div class="dropdown-header">
                                                                            <small
                                                                                class="d-block"
                                                                                style={{ fontSize: "10px", marginTop: "10px" }}
                                                                            >{`ID ${offers._id}`}</small>
                                                                            <h6 class="text-sm font-semibold">People who used this token</h6>
                                                                        </div>
                                                                        <div className="dropdown-item d-flex align-items-center">
                                                                            {" "}
                                                                            {offers.user_identifier ? (
                                                                                <div className="flex-none">
                                                                                    <img
                                                                                        alt="..."
                                                                                        className="avatar avatar-xs rounded-circle"
                                                                                        src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg"
                                                                                    />
                                                                                </div>
                                                                            ) : null}{" "}
                                                                            <div className="flex-fill ms-3">
                                                                                <a href="#" className="text-sm text-heading font-semibold mb-0">
                                                                                    {" "}
                                                                                    {offers && offers.user_identifier ? (
                                                                                        offers.user_identifier.account ? (
                                                                                            `${offers.user_identifier.account}`
                                                                                        ) : (
                                                                                            "Token sin utilizar"
                                                                                        )
                                                                                    ) : (
                                                                                        <div style={{ fontWeight: "300", fontSize: "12px" }}>
                                                                                            Token not used
                                                                                        </div>
                                                                                    )}{" "}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* MODAL OFFER INFORMATION */}

                                            <div className="modal fade" id="modalInstallApp" tabindex="-1" aria-labelledby="modalInstallApp" style={{ display: "none" }} aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    {selectedOffersItem && (
                                                        <div class="modal-content">
                                                            <div class="modal-body px-12">
                                                                <div class="d-flex align-items-center justify-content-center gap-5 mt-8">
                                                                    <div>
                                                                        <div className="avatar bg-primary text-white">M</div>
                                                                    </div>
                                                                    <div>
                                                                        <i class="bi bi-arrow-left-right text-muted"></i>
                                                                    </div>
                                                                    <div>
                                                                        <img alt="..." class="avatar rounded" src={NetflixIcon} />
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-column align-items-center text-center">
                                                                    <h5 class="text-center mt-6 mb-4">
                                                                        Connect service to you Miremoslo account
                                                                    </h5>
                                                                    <div class="d-inline-flex align-items-center rounded-pill bg-surface-secondary">
                                                                        <div>
                                                                            <a
                                                                                href="#"
                                                                                class="avatar avatar-sm bg-warning rounded-circle text-white"
                                                                            >
                                                                                P
                                                                            </a>
                                                                        </div>{" "}
                                                                        {selectedOffersItem.service_type === "unique_services" && (
                                                                            <div class="ps-3 pe-4 d-none d-lg-block">
                                                                                <a class="h6 text-sm font-semibold">Play Unique</a>
                                                                            </div>
                                                                        )}{" "}
                                                                        {selectedOffersItem.service_type === "monthly_services" && (
                                                                            <div class="ps-3 pe-4 d-none d-lg-block">
                                                                                <a class="h6 text-sm font-semibold">Play Monthly</a>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div class="mt-10">
                                                                    <h6>Features:</h6>
                                                                    <ul class="list-unstyled mt-3">
                                                                        <li
                                                                            class="py-1 d-flex align-items-center"
                                                                            style={{ marginTop: "-10px" }}
                                                                        >
                                                                            <div>
                                                                                <span class="text-xl text-success me-3 svg-icon svg-align-baseline">
                                                                                    <i class="bi bi-check"></i>
                                                                                </span>
                                                                            </div>
                                                                            <p style={{ fontSize: "12px" }}>
                                                                                Offer {`${selectedOffersItem.offer_identifier}`}
                                                                            </p>
                                                                        </li>
                                                                        <li
                                                                            class="py-1 d-flex align-items-center"
                                                                            style={{ marginTop: "-15px" }}
                                                                        >
                                                                            <div>
                                                                                <span class="text-xl text-success me-3 svg-icon svg-align-baseline">
                                                                                    <i class="bi bi-check"></i>
                                                                                </span>
                                                                            </div>{" "}
                                                                            {selectedOffersItem.service_type === "unique_services" && (
                                                                                <p style={{ fontSize: "12px" }}>Unique service type </p>
                                                                            )}{" "}
                                                                            {selectedOffersItem.service_type === "monthly_services" && (
                                                                                <p style={{ fontSize: "12px" }}>Monthly service type </p>
                                                                            )}
                                                                        </li>
                                                                        <li
                                                                            class="py-1 d-flex align-items-center"
                                                                            style={{ marginTop: "-15px" }}
                                                                        >
                                                                            <div>
                                                                                <span class="text-xl text-success me-3 svg-icon svg-align-baseline">
                                                                                    <i class="bi bi-check"></i>
                                                                                </span>
                                                                            </div>
                                                                            <p style={{ fontSize: "12px" }}>
                                                                                For activated {`${selectedOffersItem.activated}`}
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                </div>{" "}
                                                                {updateMessage ? (
                                                                    <div className="text-success" style={{ fontSize: "10px", textAlign: "center", top: "20px", right: "20px" }}>
                                                                        {" "}
                                                                        {updateMessage}{" "}
                                                                    </div>
                                                                ) : (
                                                                    <div style={{ backgroundColor: "transparent" }}>
                                                                        <div className="d-flex justify-content-end">
                                                                            {" "}
                                                                            {/* Utilizar la clase d-flex y justify-content-end para alinear el contenido a la derecha */}{" "}
                                                                            <div>
                                                                                <a
                                                                                    key={selectedOffersItem._id}
                                                                                    type="button"
                                                                                    class={`text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block ${selectedOffersItem && selectedOffersItem._id === selectedOffersItem._id ? 'selected' : ''}`}
                                                                                    onClick={() => {
                                                                                        setSelectedButton('activatedOffer');
                                                                                        setSelectedOffersItem(selectedOffersItem);
                                                                                    }}>
                                                                                    {" "}
                                                                                    <i className="bi bi-question-circle-fill"></i>
                                                                                    <span className="d-none d-sm-inline ms-2">¿Offer activated?</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal" >
                                                                    Cancel
                                                                </button>
                                                                <a href={`${selectedOffersItem.token}`} type="button" class="btn btn-sm btn-primary">
                                                                    Activate offer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* NUMERO DE PAGINAS DISPONIBLES */}
                                    {offersData && Object.keys(offersData).length >= 1 && (
                                        <div style={{ margin: '35px', display: 'flex', justifyContent: 'center' }}><Pagination currentPage={offersCurrentPage} totalPages={offersTotalPages} /></div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </main>
                </div >
            </div >
        </BodyAccount >

    )
}
