import React, { useEffect, useState } from 'react'
import { logout } from "../../../static/redux/_routes";

export default function DefaultHeader() {

    const handleClick = (e) => {
        e.preventDefault();
        logout();
    };

    return (
        <nav className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none bg-white border-bottom px-0 py-3" id="topbar">
            <div className="container-fluid">
                <div className="hstack gap-2">
                    <a type="button" href='/services?name=play' className="btn btn-sm btn-square bg-tertiary bg-opacity-20 bg-opacity-100-hover text-tertiary text-white-hover">N</a>
                    <a type="button" href='/services?name=plus' className="btn btn-sm btn-square bg-warning bg-opacity-20 bg-opacity-100-hover text-warning text-white-hover">S</a>
                    <a type="button" href='/services?name=go' className="btn btn-sm btn-square bg-primary bg-opacity-20 bg-opacity-100-hover text-primary text-white-hover">D</a>
                    <button type="button" className="btn btn-sm btn-square btn-neutral border-dashed shadow-none">
                        <i className="bi bi-plus-lg"></i>
                    </button>
                </div>
                <div class="flex-none d-xxl-flex align-items-center gap-4 px-4 scrollable-x">
                    <div class="d-flex gap-2 text-xs">
                        <span class="text-heading font-semibold">Anuncios:</span>
                        <span class="text-muted">Precios actualizados en servicios unicos $ 5.50 y S/ 19.00</span>
                    </div>
                </div>

                <form className="form-inline ms-auto me-4 d-none d-md-flex">
                    <div className="input-group input-group-inline shadow-none">
                        <span className="input-group-text border-0 shadow-none ps-0 pe-3">
                            <i className="bi bi-search"></i>
                        </span>
                        <input type="text" className="form-control form-control-flush" placeholder="Search" aria-label="Search" style={{ backgroundColor: 'white', opacity: '1' }} disabled />

                    </div>
                </form>
                <div className="navbar-user d-none d-sm-block">
                    <div className="hstack gap-3 ms-4">

                        {/* NOTIFICATIONS */}
                        <div className="dropdown">
                            <a href="#" className="nav-link px-3 text-base text-muted text-opacity-70 text-opacity-100-hover" id="dropdown-notifications" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-bell-fill"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end px-2" aria-labelledby="dropdown-notifications">
                                <div className="dropdown-item d-flex align-items-center">
                                    <h6 className="dropdown-header p-0 m-0 font-semibold">Notificaciones</h6>
                                </div>

                                <div className="dropdown-item py-3 d-flex" >
                                    <div>
                                        <div className="avatar bg-tertiary text-white rounded-circle">M</div>
                                    </div>
                                    <div className="flex-fill ms-3">
                                        <div className="text-sm lg-snug w-64 text-wrap">
                                            <a className="font-semibold text-heading text-primary-hover">Mapps</a> te da la bienvenida  
                                        </div>
                                        <span className="text-muted text-xs">Por el momento no tienes mensajes</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* MINI NAVBAR */}
                        <div className="dropdown">
                            <a className="d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                <div>
                                    <div className="avatar avatar-sm bg-warning rounded-circle text-white">
                                        <img alt="..." src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg" />
                                    </div>
                                </div>
                                <div className="d-none d-sm-block ms-3">
                                    <span className="h6">Jean</span>
                                </div>
                                <div className="d-none d-md-block ms-md-2">
                                    <i className="bi bi-chevron-down text-muted text-xs"></i>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                <div className="dropdown-header">
                                    <span className="d-block text-sm text-muted mb-1">Signed in as</span>
                                    <span className="d-block text-heading font-semibold">Jean Franco</span>
                                </div>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="/home">
                                    <i className="bi bi-house me-3"></i>Home </a>
                                <a className="dropdown-item" href="/services?name=play&service_type=monthly_services">
                                    <i className="bi bi-back me-3"></i>Mis servicios </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="/billing">
                                    <i className="bi bi-wallet2 me-3"></i>Transacciones </a>
                                <a className="dropdown-item" href="/chat">
                                    <i className="bi bi-chat me-3"></i>Mensajes </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="/transfer-service">
                                    <i className="bi bi-code-square me-3"></i>Transferencia </a>
                                <a type="button" className="dropdown-item" onClick={handleClick}>
                                    <i className="bi bi-box-arrow-left me-3"></i>Cerrar Sesión </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </nav>
    )
}
