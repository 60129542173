// REACT
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BodyAccount } from "../../_styled/dashboard-components";
import { getToken, paymentsRequest } from '../../../static/request/production.request';

// COMPONENTS
import Navbar from '../../_layout/_navbar/NavbarComponent';
import DefaultHeader from '../../_layout/_headers/DefaultHeader';
import Pagination from '../../../components/pagination';
import LazyLoading from '../../../components/loading';


export default function TransactionsPage() {
    const [paymentData, setPaymentsData] = useState([]);
    const [paymentsTotalPages, setPaymentsTotalPages] = useState(0);
    const [paymentsCurrentPage, setPaymentsCurrentPage] = useState(1);
    const [selectedPaymentItem, setSelectedPaymentItem] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [selectedButton, setSelectedButton] = useState('');
  
    const [deleteMessage, setDeleteMessage] = useState('');
    const [updateMessage, setUpdateMessage] = useState('');
    const [error, setError] = useState('');
    
    const [formData, setFormData] = useState({
        amount: selectedPaymentItem?.amount || '',
        feeAmount: selectedPaymentItem?.feeAmount || '',
        amountReceived: selectedPaymentItem?.amountReceived || '',
        paymentCurrency: selectedPaymentItem?.paymentCurrency || ''
    });

    const handleTransactionChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    useEffect(() => {
        if (selectedPaymentItem) {
            setFormData(selectedPaymentItem);
        }
    }, [selectedPaymentItem]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedButton === 'payments' || dataLoaded) {
                    const paymentsRequestParams = {
                        page: paymentsCurrentPage > 1 ? paymentsCurrentPage : undefined,
                        limit: '5',
                    };

                    const paymentsResponse = await paymentsRequest.get('', {
                        headers: { Authorization: getToken() },
                        params: paymentsRequestParams,
                    });

                    setPaymentsData(paymentsResponse.data.docs);
                    setPaymentsTotalPages(paymentsResponse.data.totalPages);
                    setPaymentsCurrentPage(paymentsResponse.data.currentPages);
                }

                if (selectedButton === 'updatedPayment' && selectedPaymentItem) {
                    setError(null);

                    const validateParams = {
                        isAmountNumber: /^\d+(\.\d+)?$/.test(formData.amount),
                        isFeeAmountNumber: /^\d+(\.\d+)?$/.test(formData.feeAmount),
                        isAmountReceivedNumber: /^\d+(\.\d+)?$/.test(formData.amountReceived),
                    };

                    const updateParams = {
                        amount: formData.amount,
                        feeAmount: formData.feeAmount,
                        amountReceived: formData.amountReceived,
                        paymentCurrency: formData.paymentCurrency,
                    };

                    if (
                        !validateParams.isAmountNumber ||
                        !validateParams.isFeeAmountNumber ||
                        !validateParams.isAmountReceivedNumber
                    ) {
                        setError('Only numbers are allowed in the amount, fee amount, and amount received fields.');
                        return;
                    }


                    if (!validateParams.isAmountReceivedNumber || parseFloat(formData.amountReceived) === 0) {
                        setError('Only numbers are allowed in the amount, fee amount, and amount received fields X2.');
                        return;
                    }

                    const paymentsResponse = await paymentsRequest.put(`/${selectedPaymentItem._id}`, updateParams, {
                        headers: { Authorization: getToken() },
                    });

                    setUpdateMessage(paymentsResponse.data.message);

                    setTimeout(() => {
                        window.location.href = `/apply-credit?_id=${selectedPaymentItem._id}`;
                    }, 2000);
                }

                if (selectedButton === 'deletedPayment' && selectedPaymentItem) {
                    const paymentsResponse = await paymentsRequest.delete(`/${selectedPaymentItem._id}`, {
                        headers: { Authorization: getToken() },
                    });

                    setSelectedButton('payments');
                    setDeleteMessage(paymentsResponse.data.message);
                }

                setDataLoaded(false);
            } catch (error) {
                console.error('Error al obtener datos del usuario:', error);
            }
        };

        fetchData();
    }, [selectedButton, selectedPaymentItem, error]);


    return (
        <BodyAccount>
            <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div class="flex-lg-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid">
                            <div className="border-bottom py-6">
                                <div className="row align-items-center">
                                    <div className="col-sm col-12">
                                        <h1 className="h2 ls-tight">
                                            <span className="d-inline-block me-3">💵</span>Transactions
                                        </h1>
                                    </div>
                                    <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                                        <div className="hstack gap-2 justify-content-sm-end">
                                            <a href="/services?name=play&service_type=monthly_services" className="btn btn-sm btn-neutral border-base">
                                                <span className="pe-2">
                                                    <i className="bi bi-people-fill"></i>
                                                </span>
                                                <span>Administrar servicios</span>
                                            </a>
                                            <a href="/home" className="btn btn-sm btn-primary">
                                                <span className="pe-2">
                                                    <i className="bi bi-plus-square-dotted"></i>
                                                </span>
                                                <span>Agregar servicios</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <main className="py-6 bg-surface-secondary">
                        <div className="container-fluid">
                            <div className="vstack gap-6">

                                {/* PAYMENTS INFORMATION */}
                                {
                                    Object.keys(paymentData).length >= 1 ? (
                                        <div className="card" key={paymentData._id} >
                                            <div className="card-header border-bottom">
                                                <h5 className="mb-0">Transacciones más recientes</h5>
                                            </div>

                                            {/* PAYMENT DATA */}
                                            <div className="table-responsive">
                                                <table className="table table-hover table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Platform</th>
                                                            <th scope="col">Created at</th>
                                                            <th scope="col">Apply Credit</th>
                                                            <th scope="col">Image</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paymentData.map((payment) => (
                                                            <tr key={payment._id} onClick={() => setSelectedPaymentItem(payment)}>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="icon icon-shape rounded-3 text-lg bg-blue-500 text-white">
                                                                            <i class="bi bi-cash-coin"></i>
                                                                        </div>
                                                                        <div class="ms-3">
                                                                            <a class="d-inline-block h6 font-semibold mb-1" href="#">{`${payment.user_identifier?.account}`} </a>
                                                                            <small class="d-block" style={{ fontSize: '10px' }}>{`ID ${payment._id}`}</small>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span class="badge text-uppercase bg-soft-primary text-primary rounded-pill">{`${payment.paymentGateway} Web Transfer`}</span>
                                                                </td>
                                                                <td>
                                                                    <i class="bi bi-clock me-1 text-muted"></i> {`${payment.paymentDate}`}
                                                                </td>
                                                                <td>
                                                                    <i class="bi bi-piggy-bank"></i> {`${payment.amount} ${payment.paymentCurrency}`}
                                                                </td>

                                                                <td>
                                                                    {payment.paymentStatus === 'pending' && (<span className="badge rounded-pill bg-soft-primary text-primary">Ver ahora - Pendiente</span>)} {payment.paymentStatus === 'completed' && (<span className="badge rounded-pill bg-soft-success text-success">Ver ahora - Completado</span>)} {payment.paymentStatus === 'rejected' && (<span className="badge rounded-pill bg-soft-danger text-danger">Ver ahora - Declinado</span>)}
                                                                </td>

                                                                <td class="text-end">
                                                                    {payment.paymentStatus === 'pending' ? (

                                                                        <a type="button" class="btn btn-sm btn-square btn-neutral me-1" data-bs-target="#depositLiquidityModal" data-bs-toggle="modal"><i class="bi bi-coin"></i> </a>
                                                                    ) : (

                                                                        <a href={`/apply-credit?_id=${payment._id}`} class="btn btn-sm btn-square btn-neutral me-1">
                                                                            <i class="bi bi-pencil"></i>
                                                                        </a>
                                                                    )}

                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-sm btn-square btn-neutral text-danger-hover"
                                                                        onClick={() => {
                                                                            setUpdateMessage('');
                                                                            setDeleteMessage('');
                                                                            setSelectedButton('deletedPayment');
                                                                            setSelectedPaymentItem(payment);
                                                                        }}
                                                                    > <i class="bi bi-trash"></i>
                                                                    </button>
                                                                </td>

                                                            </tr>
                                                        ))}


                                                    </tbody>
                                                </table>
                                            </div>
                                            <div>
                                                {deleteMessage && (
                                                    <div
                                                        className="text-success"
                                                        style={{ fontSize: "10px", textAlign: "center", margin: "15px 0px 0px 0px" }}
                                                    >
                                                        {deleteMessage}
                                                    </div>
                                                )}
                                            </div>


                                            {/* MODAL OF PAYMENT DATA */}
                                            <div className="modal fade" id="depositLiquidityModal" tabindex="-1" aria-labelledby="depositLiquidityModal" style={{ display: "none" }} aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    {selectedPaymentItem && (
                                                        <div class="modal-content overflow-hidden">
                                                            <div class="modal-header pb-0 border-0">
                                                                <h1 class="modal-title h4" id="depositLiquidityModalLabel">Transaction Details</h1>
                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body undefined">
                                                                <form class="vstack gap-6">
                                                                    <div class="vstack gap-1">
                                                                        <div class="bg-surface-secondary rounded-3 p-4">
                                                                            <div class="d-flex justify-content-between text-xs text-muted">
                                                                                <span class="font-semibold">{`${selectedPaymentItem.user_identifier.account} sent you`}</span>
                                                                                {/* <span>{`Balance: ${selectedItem.user_identifier.credit} ${selectedItem.user_identifier.currency}`}</span> */}
                                                                            </div>
                                                                            <div class="d-flex justify-content-between gap-2 mt-4">

                                                                                <input
                                                                                    className="form-control form-control-flush text-xl font-bold flex-fill"
                                                                                    id="amount"
                                                                                    name="amount"
                                                                                    value={formData.amount}
                                                                                    onChange={handleTransactionChange}
                                                                                />

                                                                                <button style={{ backgroundColor: 'transparent', opacity: '1' }} class="flex-none text-muted text-primary-hover" data-bs-toggle="dropdown" data-bs-autoclose="outside" aria-expanded="false">
                                                                                    <div class="d-flex align-items-center gap-2"><img alt="..." src="https://satoshi.webpixels.io/img/flags/eu.svg" class="w-6 h-6 rounded-circle" /> <span class="font-semibold text-sm">{formData.paymentCurrency}</span></div>
                                                                                </button>



                                                                                <div class="dropdown-menu dropdown-menu-end w-64">
                                                                                    <div class="list-group-item border rounded d-flex gap-3 p-2 rounded bg-light-hover" style={{ borderColor: 'transparent' }}>
                                                                                        <div class="icon flex-none">
                                                                                            <img src="https://satoshi.webpixels.io/img/wallets/metamask.png" class="w-10 h-10" alt="..." />
                                                                                        </div>
                                                                                        <div class="d-flex align-items-center flex-fill">
                                                                                            <div class="">
                                                                                                <a href="#" class="stretched-link text-heading font-bold" onClick={() => handleTransactionChange({ target: { name: 'paymentCurrency', value: formData.paymentCurrency === "PEN" ? "USD" : "PEN" } })}>
                                                                                                    {formData.paymentCurrency === "PEN" ? "USD" : "PEN"}
                                                                                                </a>

                                                                                            </div>
                                                                                            <div class="ms-auto">
                                                                                                <span class="badge badge-md text-bg-primary rounded-pill">Popular</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>




                                                                            </div>
                                                                        </div>
                                                                        <div class="position-relative text-center my-n4 overlap-10">
                                                                            <div class="icon icon-sm icon-shape bg-card shadow-soft-3 rounded-circle text-sm">
                                                                                <i class="bi bi-arrow-down-up"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div class="bg-surface-secondary rounded-3 p-4">
                                                                            <div class="d-flex justify-content-between text-xs text-muted">
                                                                                <span class="font-semibold">Fee</span>
                                                                                {/* <span>Creat at 29/04/2023, 10:20pm</span> */}
                                                                            </div>
                                                                            <div class="d-flex justify-content-between gap-2 mt-4">

                                                                                <input
                                                                                    className="form-control form-control-flush text-xl font-bold flex-fill"
                                                                                    placeholder="0.00"
                                                                                    id="feeAmount"
                                                                                    name="feeAmount"
                                                                                    value={formData.feeAmount}
                                                                                    onChange={handleTransactionChange}
                                                                                />

                                                                                <button style={{ backgroundColor: 'transparent', opacity: '1' }} class="flex-none text-muted text-primary-hover" data-bs-toggle="dropdown" data-bs-autoclose="outside" aria-expanded="false">
                                                                                    <div class="d-flex align-items-center gap-2"><img alt="..." src="https://satoshi.webpixels.io/img/flags/eu.svg" class="w-6 h-6 rounded-circle" /> <span class="font-semibold text-sm">{formData.paymentCurrency}</span></div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class="form-label">Amount received</label>
                                                                        <div class="d-flex flex-wrap gap-1 gap-sm-2" >
                                                                            <div class="input-group input-group-sm input-group-inline shadow-none rounded-pill">
                                                                                <input
                                                                                    style={{ textAlign: 'center' }}
                                                                                    className="form-control rounded-start-pill"
                                                                                    placeholder="0.00"
                                                                                    id="amountReceived"
                                                                                    name="amountReceived"
                                                                                    value={formData.amountReceived}
                                                                                    onChange={handleTransactionChange}
                                                                                />
                                                                                <span class="input-group-text rounded-end-pill">{`${selectedPaymentItem.user_identifier.currency}`}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {error && (<div className="text-danger" style={{ fontSize: '10px' }}> {error} </div>)}
                                                                    {updateMessage && (<div className="text-success" style={{ fontSize: '10px' }}> {updateMessage} </div>)}
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-lg btn-dark w-full rounded-pill"
                                                                        onClick={() => {
                                                                            setError('')
                                                                            setSelectedButton('updatedPayment');
                                                                            setSelectedPaymentItem(selectedPaymentItem);
                                                                        }}>Provide liquidity</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {/* PAGINATION */}
                                            {
                                                Object.keys(paymentData).length >= 1 && (
                                                    <div style={{ margin: "25px", display: "flex", justifyContent: "center" }}>
                                                        <Pagination currentPage={paymentsCurrentPage} totalPages={paymentsTotalPages} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <LazyLoading />
                                    )
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </BodyAccount>
    )
}
