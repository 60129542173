import { publicRequest } from "./../request/production.request";
import { loginFailure, loginStart, loginSuccess } from "./userRedux";


export const login = async (dispatch, { email, password }) => {
  try {
    dispatch(loginStart());
    // Realizar la petición a la API
    const response = await publicRequest.post("/auth/signin", { email, password });
    dispatch(loginSuccess(response.data));
  } catch (error) {
    dispatch(loginFailure());
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.message); // Accede al mensaje de error de la respuesta de la API
    } else {
      throw new Error("Error de autenticación"); // Lanza una excepción genérica en caso de error
    }
  }
};


export const logout = function () {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = '/';
};


