import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { BodyAccount } from '../../_styled/dashboard-components'
import { tokensRequest } from '../../../static/request/production.request';
import Navbar from '../../_layout/_navbar/NavbarComponent';
import DefaultHeader from '../../_layout/_headers/DefaultHeader';
import NetflixIcon from '../../../assents/icons/netflix.jpeg';
import StarPlusIcon from '../../../assents/icons/starplus.jpg';
import DirectvGoIcon from '../../../assents/icons/directvgo.jpg';
import Pagination from '../../../components/pagination';
import HederTitle from '../../_layout/_headers/HederTitle';


export default function TokensPage() {

    const [tokenData, setTokenData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isUniqueTypeChanged, setUniqueTypeChanged] = useState(true);
    const userId = JSON.parse(localStorage.getItem("user"))?._id;

    // Obtener la URL base sin parámetros

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentUrl = window.location.href.split('?')[0];

    // Obtener los valores de los parámetros de consulta
    const page = searchParams.get('page');
    const name = searchParams.get('name');
    const user_identifier = searchParams.get('user_identifier');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user_identifier) {
                    const queryParams = {
                        name: capitalizeString(name),
                        page: page || 1
                    };

                    const queryStringParams = new URLSearchParams(queryParams).toString();
                    const response = await tokensRequest.get(`/user/${user_identifier}?${queryStringParams}`);

                    setTokenData(response.data.docs);
                    setTotalPages(response.data.totalPages);
                    setCurrentPage(response.data.currentPage);
                }
            } catch (error) {
                console.error('Error al obtener los datos de suscripción:', error);
            }
        };

        fetchData();
    }, [user_identifier, name, page]);

    const capitalizeString = (str) => {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }
        return '';
    };

    const renderImage = () => {
        const paramName = searchParams.get('name')?.toLowerCase();

        if (paramName === 'play') {
            return <img className="rounded-circle avatar avatar-sm me-3" src={NetflixIcon} />;
        } else if (paramName === 'plus') {
            return <img className="rounded-circle avatar avatar-sm me-3" src={StarPlusIcon} />;
        } else if (paramName === 'go') {
            return <img className="rounded-circle avatar avatar-sm me-3" src={DirectvGoIcon} />;
        }
        return null;
    };

    if (!userId) {
        return <Redirect replace to="/login" />;
    }

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid"> <div className="border-bottom pt-6"> <HederTitle title='Mis servicios' icon='👾' /> <ul className="nav nav-tabs overflow-x border-0"> <li className="nav-item"> <a href={`${currentUrl}?name=play${'&user_identifier=' + user_identifier}`} className={`nav-link ${capitalizeString(name) === 'Play' ? 'active' : ''}`}> Plataforma Play </a> </li> <li className="nav-item"> <a href={`${currentUrl}?name=plus${'&user_identifier=' + user_identifier}`} className={`nav-link ${capitalizeString(name) === 'Plus' ? 'active' : ''}`}> Plataforma Plus </a> </li> <li className="nav-item"> <a href={`${currentUrl}?name=go${'&user_identifier=' + user_identifier}`} className={`nav-link ${capitalizeString(name) === 'Go' ? 'active' : ''}`}> Plataforma Go </a> </li> </ul> </div> </div>
                    </header>
                    <main className="py-6 bg-surface-secondary">
                        <div className="container-fluid">
                            <div className="vstack gap-6">

                                {/* HERRAMIENTAS DE SELECCIÓN */}
                                <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
                                    <div className="d-flex gap-3">
                                        <div className="input-group input-group-sm input-group-inline">
                                            <span className="input-group-text pe-2">
                                                <i className="bi bi-search"></i>
                                            </span>
                                            <input type="text" className="form-control" placeholder="Search" aria-label="Search" style={{ backgroundColor: 'white', opacity: '1' }} disabled />
                                        </div>
                                        <div>
                                            <a type="button" href='/purchases' className="btn btn-sm px-6 btn-neutral d-flex align-items-center">
                                                <i class="bi bi-shop-window"></i>
                                                <span style={{ marginLeft: '10px' }}>Compras</span>
                                                <span style={{ marginLeft: '5px' }}>Adicionales</span>
                                                <span className="vr opacity-20 mx-4"></span>
                                                <span className="text-xs text-primary">Mostrar</span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="btn-group">
                                        <a href={`/services?name=${name}${'&user_identifier=' + user_identifier}`} type='buttom' className={`btn btn-sm btn-neutral ${isUniqueTypeChanged === false ? 'active text-primary' : ''}`} onClick={() => setUniqueTypeChanged(false)}>Categoria mensual</a>

                                        <a  type='buttom' className={`btn btn-sm btn-neutral ${isUniqueTypeChanged === true ? 'active text-primary' : ''}`} onClick={() => setUniqueTypeChanged(true)}>Categoria única</a>
                                    </div>

                                </div>

                                {/* SERVICIOS UNICOS */}
                                {tokenData.length >= 1 && (
                                    <div className="card" key={tokenData._id}>
                                        <div className="card-header border-bottom d-flex align-items-center">
                                            <h4 className="me-auto">Ofertas</h4>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">Identificador</th>
                                                        <th scope="col">Plataforma</th>
                                                        <th scope="col">Facturación</th>
                                                        <th scope="col">Precio</th>
                                                        <th scope="col">Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody> {tokenData.map((tokens) => (<tr> {/* IMG Default */} <td> {renderImage()} <a className=" font-semibold" href={tokens.offer.mappsOffer || null} style={{ color: "#1f64e9f5", fontSize: '13px', marginRight: '20px' }}> {tokens.offer.mappsId || "Uops, no tienes ninguna activación única disponible."} </a>
                                                </td>
                                                    <td>
                                                        <p className="text-heading font-semibold"> {`Servicio de Streaming`} </p>
                                                    </td>
                                                    <td>
                                                        <i className="bi bi-check-circle" style={{ "margin-right": "3px" }}></i> {tokens.subscription.renewalDate}
                                                    </td>
                                                    <td> {`${tokens.subscription.price.amount} ${tokens.subscription.price.currency}`} </td>
                                                    <td>
                                                        <span className="badge badge-lg badge-dot">
                                                            <span className={tokens.subscription.status === 'Proceso' ? 'text-secondary me-2' : tokens.subscription.status === 'Completado' ? 'text-primary me-2' : tokens.subscription.status === 'Activo' ? 'text-success me-2' : tokens.subscription.status === 'Suspender' ? 'text-warning me-2' : tokens.subscription.status === 'Cancelado' ? 'text-danger me-2' : ''}> ● </span> {tokens.subscription.status} </span>
                                                    </td> {/* Boton */} <td className="text-end">
                                                        <a className="btn btn-sm btn-neutral" role="button" href={tokens.offer.mappsOffer} target="_blank" rel="noreferrer"> Activar </a>
                                                    </td>
                                                </tr>))} </tbody>
                                            </table>
                                        </div>
                                        <div className="card-footer border-0 py-5">
                                            <span className="text-muted text-sm">Controla el acceso a estos servicios, ve los detalles más recientemente y más.</span>
                                        </div>
                                    </div>
                                )}

                                {/* NUMERO DE PAGINAS DISPONIBLES */}
                                {tokenData.length >= 1 && (
                                    <div><Pagination currentPage={currentPage} totalPages={totalPages} platform={name} user_identifier={userId} /></div>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </BodyAccount>
    )
}
