import styled from "styled-components";

export const BodyLogin = styled.form`
    font-family: 'Noto Sans JP', sans-serif;
`;

export const ContainerLogin = styled.form`
  img {
    -webkit-user-drag: none;
    user-select: none;
  }

  input, textarea, button {
    border-radius: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: disc;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  p {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`;

export const LoginLayaout = styled.form`
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: 15px;
`;

export const LoginHeader = styled.form`
    padding: 0px 2.5rem;
    max-width: 77.5rem;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    z-index: var(--z-index-header);
    padding: 0px 1.25rem;
    height: 4rem;
`;

export const LogoButton = styled.form`
    flex: 0 1 8rem;
    min-width: 7.1875rem;
`

export const SpaceForLogin = styled.form`
    --horizontal-spacing: 2.5rem;
    --fixed-bottom-spacing: 1.5rem;
    --gutter: 72;
    --top-spacing: var(--gutter-in-rem);
    -webkit-box-align: center;
    align-items: center;

    --fixed-bottom-spacing: 1rem;
    --gutter-in-rem: calc(var(--gutter) * 0.0625rem);
    --top-spacing: var(--gutter-in-rem);
    display: flex;
    flex: 1 1 auto;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 4rem 0 var(--fixed-bottom-spacing);
    padding: var(--top-spacing) 0 var(--gutter-in-rem);
`

export const ContentSpaceForLogin = styled.div`
    --horizontal-spacing: 2.5rem;

    --horizontal-spacing: 1.25rem;
    width: 100%;
    max-width: 77.5rem;
    padding: 0 var(--horizontal-spacing);
`;

export const AuthLayout = styled.div`
  max-width: 25rem;

  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin: 0px auto;
`;


export const AuthForm = styled.form`
  /* Estilos para el formulario de autenticación */
`;

export const TitleHeading = styled.h1`
  font-size: 1.75rem;
  line-height: 2.25rem;

  margin-bottom: 1.5rem;
  font-weight: 500;
  text-rendering: optimizelegibility;
  font-size: 1.5rem;
  line-height: 2rem;
`;

export const InputContainer = styled.div`
  margin-bottom: 1.25rem; 
`;

export const FormInput = styled.input`
  /* Estilos para el control de formulario */
`;

export const ForgotPassword = styled.span`
    margin-bottom: 2rem

    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 1.5rem;
    margin-bottom: 2.2rem;
`;

export const ActionLink = styled.a`
  /* Estilos para el enlace de acción */
`;

export const TextRegister = styled.span`
  color: #0e0f0fe2; /* Color de texto */
  font-size: 13px; /* Tamaño de fuente de 13px */
`;

export const Button = styled.button`
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    min-width: 7.5rem;
    height: 2.5rem;
    border: 0px;
    cursor: pointer;
    text-decoration: none;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-property: color, background-color, box-shadow;
    user-select: none;
    padding: 0px 1rem;
    width: 100%;
    color: rgb(20 21 25);
    background: linear-gradient(135deg, rgb(247 89 89) 0%, rgb(243 85 135) 100%);
    border: 1px solid #f35588;
    color: #fff;
`;

export const Footer = styled.div`
position: fixed;
bottom: 0;
left: 0;
right: 0;
padding: 35px;
text-align: center;
`;

export const FooterText = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  --x-text-opacity: 1;
  color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
`;

export const TextError = styled.p`
font-size: 0.875rem;
font-weight: 500;
--x-text-opacity: 1;
color: red;
`;