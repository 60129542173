import React, { useEffect, useRef, useState } from 'react'
import { getToken, serviceRequest } from '../../../static/request/production.request';
import { BodyAccount } from "../../_styled/dashboard-components";
import Navbar from '../../_layout/_navbar/NavbarComponent';
import DefaultHeader from '../../_layout/_headers/DefaultHeader';

export default function TransferServicePage() {
    const [serviceTransfersData, setServiceTransfersData] = useState([]);
    const [selectedServiceTransferItem, setSeletedServiceTransferItem] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [selectedButton, setSelectedButton] = useState('');
    const emailValueRef = useRef('');

    // DELETE MESSAGE STATUS FOR MODAL TYPE
    const [updateMessage, setUpdateMessage] = useState('');
    const [deleteMessage, setDeleteMessage] = useState('');
    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                let servicesResponse = null;

                if (selectedButton === 'transferServices' || dataLoaded) {
                    const servicesRequestParams = {
                        page: '1',
                        limit: '9',
                    };

                    servicesResponse = await serviceRequest.get('', {
                        headers: { Authorization: getToken() },
                        params: servicesRequestParams,
                    });

                    setServiceTransfersData(servicesResponse.data.docs);
                }

                if (selectedButton === 'modifyServiceTransfer' && selectedServiceTransferItem) {
                    const updateParams = {
                        traslateEmail: emailValueRef.current,
                    };

                    servicesResponse = await serviceRequest.put(`/${selectedServiceTransferItem._id}`, updateParams, {
                        headers: { Authorization: getToken() },
                    });

                    setSelectedButton('transferServices');
                    setUpdateMessage(servicesResponse.data.message);
                }

                if (selectedButton === 'deletedServiceTransfer' && selectedServiceTransferItem) {
                    servicesResponse = await serviceRequest.delete(`/${selectedServiceTransferItem._id}`, {
                        headers: { Authorization: getToken() },
                    });

                    setSelectedButton('transferServices');
                    setDeleteMessage(servicesResponse.data.message);
                }

                setDataLoaded(false);
            } catch (error) {
                console.log('Error al obtener datos del usuario:', error);
            }
        };

        fetchData();
    }, [selectedButton]);

    const handleInputValueChange = (event) => {
        const inputValue = event.target.value;
        emailValueRef.current = inputValue;

        // Validar el formato de correo electrónico utilizando una expresión regular
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(inputValue)) {
            setEmailError('Por favor, ingresa un correo electrónico válido.');
        } else {
            setEmailError('');
        }
    };

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />

                    <header>
                        <div className="container-fluid"> <div className="border-bottom pt-6"> <div className="row align-items-center"> <div className="col-sm col-12"> <h1 className="h2 ls-tight"> <span className="d-inline-block me-3">📫</span>Transfer services </h1> </div> <div className="col-sm-auto col-12 mt-4 mt-sm-0"> <div className="hstack gap-2 justify-content-sm-end"> <a href="/services?name=play&service_type=monthly_services" className="btn btn-sm btn-neutral border-base"> <span className="pe-2"> <i className="bi bi-people-fill"></i> </span> <span>Administrar servicios</span> </a> <a href="/home" className="btn btn-sm btn-primary"> <span className="pe-2"> <i className="bi bi-plus-square-dotted"></i> </span> <span>Agregar servicios</span> </a> </div> </div> </div> <ul className="nav nav-tabs overflow-x border-0"> <li className="nav-item"> <a href="/transfer-service" className="nav-link active">Data transfer services</a> </li> </ul> </div> </div>
                    </header>

                    <main class="py-6 bg-surface-secondary">
                        <div class="container-fluid max-w-screen-md vstack gap-6">

                            {/* GET */}
                            {Object.keys(serviceTransfersData).length >= 1 && (
                                <div class="card">
                                    <div class="card-body pb-0">
                                        <div class="mb-4">
                                            <h4 class="mb-1">Manage access</h4>
                                            <p class="text-sm text-muted">Manage and control Team Members.</p>
                                        </div>
                                        <div class="list-group list-group-flush" style={{ marginTop: '-15px' }}>

                                            {serviceTransfersData.map((serviceTransfer) => (
                                                <div class="list-group-item d-flex align-items-center" key={serviceTransfer._id} onClick={() => setSeletedServiceTransferItem(serviceTransfer)} >
                                                    <div class="me-4">
                                                        <div class="avatar w-10 h-10 rounded-circle">
                                                            <img alt="..." src={serviceTransfer.user_identifier.profile} />
                                                        </div>
                                                    </div>
                                                    <div class="flex-fill">
                                                        <a class="d-block h6 font-semibold mb-1">{`${serviceTransfer.user_identifier.account}`}</a>
                                                        <span class="d-block text-sm text-muted">{`${serviceTransfer.traslateEmail || 'Transfer services are not available'}`}</span>
                                                    </div>
                                                    <div class="ms-auto text-end">
                                                        <div class="dropdown">
                                                            <a class="text-muted" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i class="bi bi-three-dots-vertical"></i>
                                                            </a>
                                                            <div class="dropdown-menu dropdown-menu-end" style={{ "position": "absolute", "inset": "0px 0px auto auto", "margin": "0px", "transform": "translate3d(0px, 20px, 0px)" }} data-popper-placement="bottom-end">
                                                                <a href="#modalAddUsers" className="dropdown-item" data-bs-toggle="modal">Action </a>
                                                                <a type='button' class="dropdown-item" onClick={() => { setSelectedButton('deletedServiceTransfer'); }}>
                                                                    Another action
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {deleteMessage && (
                                                <div className="text-success" style={{ fontSize: '10px', textAlign: 'center', padding: '10px' }}> {deleteMessage} </div>
                                            )}

                                            {/* MODAL USER INFORMATION */}

                                            <div className="modal fade" id="modalAddUsers" tabindex="-1" aria-labelledby="modalAddUsers" style={{ display: "none" }} aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    {selectedServiceTransferItem && (
                                                        <div className="modal-content shadow-3">
                                                            <div className="modal-header justify-content-start">
                                                                <div className="icon icon-shape rounded-3 bg-soft-primary text-primary text-lg me-4">
                                                                    <i className="bi bi-person-square"></i>
                                                                </div>
                                                                <div>
                                                                    <h5 className="mb-1">Service Transfer</h5>
                                                                </div>
                                                            </div>
                                                            <div className="modal-body py-0 scrollable-y" style={{ maxHeight: "425px" }}>
                                                                <div className="list-group list-group-flush">
                                                                    <div style={{ margin: '20px 30px 30px 30px' }}>
                                                                        {/* <h5>Clever version</h5>
                                                                        <p className="text-sm text-muted mt-1">Show apps working with:</p> */}
                                                                        <div className="vstack gap-3 mt-4">
                                                                            <div className="form-item-checkable">
                                                                                <label class="form-label">Email</label>
                                                                                <input type="email" className="form-control" placeholder="Write traslate email" onChange={handleInputValueChange} />
                                                                            </div>
                                                                            <div className="form-item-checkable">
                                                                                <label class="form-label">Password</label>
                                                                                <input type="text" class="form-control" placeholder="Project name" />
                                                                            </div>
                                                                            {emailError && <p style={{ fontSize: '12px' }}>{emailError}</p>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {updateMessage && (
                                                                <div className="text-success" style={{ fontSize: '10px', textAlign: 'center', padding: '10px' }}> {updateMessage} </div>
                                                            )}
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-sm btn-neutral" data-bs-dismiss="modal">Close</button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-primary"
                                                                    onClick={() => {
                                                                        setSelectedButton('modifyServiceTransfer');
                                                                    }}>
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </main>

                </div>
            </div >
        </BodyAccount >
    )
}
