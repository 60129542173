import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Importar hook useLocation de react-router-dom
import { paymentsRequest } from '../../../static/request/production.request';
import { BodyAccount } from "../../_styled/dashboard-components";
import Navbar from '../../_layout/_navbar/NavbarComponent';
import DefaultHeader from '../../_layout/_headers/DefaultHeader';

export default function ApplyCreditPage() {

    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user ? user._id : null;

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const transaction_id = searchParams.get('_id');

    const [billing, setBillingData] = useState({});
    const [billingUpdate, setBillingUpdate] = useState({});
    const [billingError, setBillingErrorData] = useState(null);
    const [updateMessage, setUpdateMessage] = useState('');
    const [applyCreditMessage, setApplyCreditMessage] = useState('');
    const [isInputValueChanged, setIsInputValueChanged] = useState(false);


    useEffect(() => {
        const fetchBillingData = async () => {
            try {
                if (transaction_id) {
                    const res = await paymentsRequest.get(`/${transaction_id}`);
                    setBillingData(res.data);
                }
            } catch (error) {
                setBillingErrorData(error.response ? error.response.status : 'Unknown Error');
            }
        };

        if (userId) {
            fetchBillingData();
        }
    }, [userId, transaction_id]);

    const updateBillingData = (field, value) => {
        setBillingUpdate(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleSaveClick = async (e) => {
        // Lógica para guardar los datos de la factura
        e.preventDefault();

        try {
            const res = await paymentsRequest.put(`/${transaction_id}`, billingUpdate);
            setUpdateMessage(res.data.message);

            // Esperar 2 segundos antes de recargar la página
            setTimeout(() => {
                window.location.reload();
            }, 2000); // 2000 milisegundos = 2 segundos

        } catch (error) {
            console.error('Error al eliminar transacción:', error);
        }
    };

    const handleResetDataClick = async (e) => {
        // Lógica para guardar los datos de la factura
        e.preventDefault();

        const queryParams = {
            resetData: true,
        };

        try {
            const res = await paymentsRequest.put(`/${transaction_id}`, { payment_response: queryParams });
            setApplyCreditMessage(res.data.message);

            // Esperar 2 segundos antes de recargar la página


        } catch (error) {
            console.error('Error al eliminar transacción:', error);
        }
    };


    const handleApplyCreditClick = async (e) => {
        // Lógica para guardar los datos de la factura
        e.preventDefault();

        const queryParams = {
            applyCredit: true,
        };

        try {
            const res = await paymentsRequest.put(`/${transaction_id}`, { payment_response: queryParams });
            setApplyCreditMessage(res.data.message);

        } catch (error) {
            console.error('Error al eliminar transacción:', error);
        }
    };

    return (
        <BodyAccount>
            <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div class="flex-lg-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid">
                            <div className="border-bottom pt-6">
                                <div className="row align-items-center">
                                    <div className="col-sm col-12">
                                        <h1 className="h2 ls-tight">
                                            <span className="d-inline-block me-3">💰</span>Apply Credit
                                        </h1>
                                    </div>
                                    <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                                        <div className="hstack gap-2 justify-content-sm-end">
                                            <a href="/services?name=play&service_type=monthly_services" className="btn btn-sm btn-neutral border-base">
                                                <span className="pe-2">
                                                    <i className="bi bi-people-fill"></i>
                                                </span>
                                                <span>Administrar servicios</span>
                                            </a>
                                            <a href="/home" className="btn btn-sm btn-primary">
                                                <span className="pe-2">
                                                    <i className="bi bi-plus-square-dotted"></i>
                                                </span>
                                                <span>Agregar servicios</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-tabs overflow-x border-0">
                                    <li className="nav-item">
                                        <a href="/transactions" className="nav-link ">All transactions</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/apply-credit" className="nav-link active">Apply Credit</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </header>

                    {transaction_id !== '' ? (
                        <main class="py-6 bg-surface-secondary" >
                            {Object.keys(billing).length > 1 && billingError != '500' ? (
                                <div class="container-fluid max-w-screen-md vstack gap-6">
                                    <div class="card">

                                        {/* DETAILS OF TRANSACTIONS */}
                                        <div class="card-body py-4"><div class="d-flex align-items-center justify-content-between mb-5"><div class="flex-1"><h6 class="h5 font-semibold mb-1">Standard Plan</h6><p class="text-sm text-muted">The perfect way to get started.</p></div><div class="ms-auto"><div class="d-flex align-items-center mt-5 mb-3 lh-none text-heading d-block display-5 ls-tight mb-0"><span class="font-semibold text-2xl align-self-start mt-1 mt-sm-1 me-1">$</span> <span>{`${billing.amountReceived}`}</span> <span class="d-inline-block font-regular text-muted text-lg mt-sm-3 ms-1">/ {`${billing.paymentCurrency}`}</span></div></div></div><div class="d-flex align-items-center justify-content-between mb-1"><span class="text-sm text-muted font-semibold text-heading d-block">30 days</span> {billing.paymentStatus === 'processing' && (<span class="text-sm text-muted font-semibold d-block">Procesando</span>)} {billing.paymentStatus === 'completed' && (<span class="text-sm text-muted font-semibold d-block">Completado</span>)} {billing.status === 'rejected' && (<span class="text-sm text-muted font-semibold d-block">Declinado</span>)} </div> <div class="progress progress-sm shadow-none mb-6"> {billing.paymentStatus === 'processing' && (<div class="progress-bar bg-primary" role="progressbar" style={{ width: '50%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>)} {billing.paymentStatus === 'completed' && (<div class="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>)} {billing.paymentStatus === 'rejected' && (<div class="progress-bar bg-danger" role="progressbar" style={{ width: '100%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>)} </div>

                                            <div>
                                                {applyCreditMessage ?
                                                    (
                                                        <div className="text-success" style={{ fontSize: '10px', top: '20px', right: '20px' }}>
                                                            {applyCreditMessage} </div>
                                                    )
                                                    :
                                                    (
                                                        <div>
                                                            <hr className="my-4" />
                                                            <div className="d-flex align-items-center justify-content-between">

                                                                {billing.paymentStatus !== 'pending' && (
                                                                    <>
                                                                        {billing.isComplete === false && isInputValueChanged === false && (
                                                                            <a type='button' className="text-muted text-danger-hover text-sm font-semibold" onClick={() => setIsInputValueChanged(true)}>Update Values</a>
                                                                        )}

                                                                        {billing.isComplete === true && isInputValueChanged === false && (
                                                                            <a type='button' className="text-muted text-danger-hover text-sm font-semibold" onClick={handleResetDataClick}>Reset data</a>
                                                                        )}


                                                                        {billing.paymentStatus !== 'completed' && isInputValueChanged === false && (
                                                                            <button href="#" className="btn btn-sm btn-neutral" onClick={handleApplyCreditClick}>Apply Credit</button>
                                                                        )}
                                                                    </>
                                                                )}

                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>

                                    </div>



                                    <div className="card">

                                        {/* DETAILS OF TRANSACTIONS */}
                                        <div className="card-body pb-0">
                                            <div className="mb-7">
                                                <h4 className="mb-1">Billing Details</h4>
                                                <p className="text-sm text-muted">By filling your data you get a much better experience using our website.</p> {updateMessage && (<div className="text-success" style={{ fontSize: '10px', position: 'absolute', top: '20px', right: '20px', }}> {updateMessage} </div>)}
                                            </div>
                                            <div className="row g-5">
                                                <div className="col-md-6">
                                                    <div>
                                                        <label className="form-label text-center">Amount</label>
                                                        <input type="text" className="form-control" defaultValue={billing.amountReceived ? billing.amountReceived : ''} style={{ textAlign: 'center' }} onChange={(e) => updateBillingData('amountReceived', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <label className="form-label">Currency</label>
                                                        <input type="text" className="form-control" defaultValue={billing.paymentCurrency ? billing.paymentCurrency : ''} style={{ textAlign: 'center' }} onChange={(e) => updateBillingData('paymentCurrency', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div>
                                                        <label className="form-label">Date</label>
                                                        <input type="text" className="form-control" defaultValue={billing.paymentDate ? billing.paymentDate : ''} style={{ textAlign: 'center' }} onChange={(e) => updateBillingData('paymentDate', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <label className="form-label">Billing Type</label>
                                                        <input type="text" className="form-control" defaultValue={billing.paymentGateway ? billing.paymentGateway : ''} style={{ textAlign: 'center' }} onChange={(e) => updateBillingData('paymentGateway', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <label className="form-label">User</label>
                                                        <input type="text" className="form-control" defaultValue={billing.user_account ? billing.user_account : ''} style={{ textAlign: 'center' }} onChange={(e) => updateBillingData('user_account', e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* BUTTONS FOR SAVE AND UPDATE AMOUNTS */}
                                        <div className="card-footer text-end">
                                            {
                                                billing.paymentStatus === 'processing' && isInputValueChanged === true && (<> <button type="button" className="btn btn-sm btn-neutral me-2" onClick={() => setIsInputValueChanged(false)}> Cancel </button> <button className="btn btn-primary" onClick={handleSaveClick}> Save </button> </>)}
                                            {
                                                billing.paymentStatus === 'pending' && (<> <a type="button" href='/transactions' className="btn btn-sm btn-neutral me-2"> Close </a> <button className="btn btn-primary" onClick={handleSaveClick}> Save </button> </>)
                                            }
                                        </div>

                                    </div>

                                </div>
                            ) : (
                                <div class="text-center"> <p class="text-muted">No billing details found.</p> </div>
                            )}
                        </main>
                    ) : (
                        <main class="py-6 bg-surface-secondary" > <div class="text-center"> <p class="text-muted">Select a valid billing details here.</p> </div> </main>
                    )}

                </div>
            </div>
        </BodyAccount >
    )
}
