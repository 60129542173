import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import AuthPage from "../pages/presentation/auth/AuthPage";
import DashboardPage from "../pages/presentation/dashboard/DashboardPage";
import ServicePage from "../pages/presentation/services/ServicesPage";
import SubscriptionsPage from "../pages/presentation/services/subscriptions/SubscriptionsPage";
import PurchasesPage from "../pages/presentation/services/PurchasesPage";
import TransactionsPage from "../pages/presentation/transactions/TransactionsPage";
import ApplyCreditPage from "../pages/presentation/transactions/ApplyCreditPage";
import ApplicationsPage from "../pages/presentation/integrations/ApplicationsPage";
import GeneralPage from "../pages/presentation/settings/GeneralPage";
import PricingPage from "../pages/presentation/settings/PricingPage";
import TransferServicePage from "../pages/presentation/resources/TransferServicePage";
import UsersPage from "../pages/presentation/users/UsersPage";
import SupportPage from "../pages/presentation/support/SupportPage";
import TokensPage from "../pages/presentation/services/TokensPage";


const App = () => {
  const user = useSelector((state) => state.user.currentUser);

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/home" component={DashboardPage} isAuthenticated={user} />

        {/* Resto de las rutas */}
        <PrivateRoute path="/apply-credit" component={ApplyCreditPage} isAuthenticated={user} />
        <PrivateRoute path="/transactions" component={TransactionsPage} isAuthenticated={user} />
        <PrivateRoute path="/applications" component={ApplicationsPage} isAuthenticated={user} />
        <PrivateRoute path="/settings" component={GeneralPage} isAuthenticated={user} />
        <PrivateRoute path="/pricing" component={PricingPage} isAuthenticated={user} />
        <PrivateRoute path="/users" component={UsersPage} isAuthenticated={user} />
        <PrivateRoute path="/transfer-service" component={TransferServicePage} isAuthenticated={user} />
        <PrivateRoute path="/services/:id" component={SubscriptionsPage} isAuthenticated={user} />
        <PrivateRoute path="/services" component={ServicePage} isAuthenticated={user} />
        <PrivateRoute path="/purchases" component={PurchasesPage} isAuthenticated={user} />
        <PrivateRoute path="/tokens" component={TokensPage} isAuthenticated={user} />
        <PrivateRoute path="/support" component={SupportPage} isAuthenticated={user} />


        {/* Rutas de autenticación */}
        <Route exact path="/login" render={() => (user ? <Redirect to="/home" /> : <AuthPage />)} />

        {/* Ruta por defecto */}
        <Redirect to="/home" />
      </Switch>
    </Router>
  );
};

// Componente para rutas protegidas
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default App;