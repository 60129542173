import "../../../styles/styles.css"
import { logout } from "../../../static/redux/_routes";

export default function Navbar() {

    const handleClick = (e) => {
        e.preventDefault();
        logout();
    };

    return (
        <nav class="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg scrollbar">
            <div className="container-fluid">
                <button class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="/#"><img style={{ "height": "36px" }} src="https://mapps.click/static/images/Logo_Miremoslo_Net.jpg" alt="..." /></a>
                <div class="navbar-user d-lg-none">
                    <div class="dropdown">
                        <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="avatar-parent-child">
                                <img alt="..." src="https://mapps.click/static/images/iconProfileIMG.png" class="avatar avatar- rounded-circle" />
                                <span class="avatar-child avatar-badge bg-success"></span>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                            <a className="dropdown-item" href="/home"> <i className="bi bi-house me-3"></i>Home </a>
                            <a className="dropdown-item" href="/services?name=play&service_type=monthly_services"> <i className="bi bi-back me-3"></i>Mis servicios </a>

                            <div className="dropdown-divider"></div>

                            <a className="dropdown-item" href="/billing"> <i className="bi bi-wallet2 me-3"></i>Transacciones </a>
                            <a className="dropdown-item" href="/chat"> <i className="bi bi-chat me-3"></i>Mensajes </a>

                            <div className="dropdown-divider"></div>

                            <a className="dropdown-item" href="/transfer-service"> <i className="bi bi-code-square me-3"></i>Transferencia </a>
                            <a className="dropdown-item" onClick={handleClick}> <i className="bi bi-box-arrow-left me-3"></i>Cerrar Sesión </a>
                        </div>
                    </div>
                </div>
                <div class="collapse navbar-collapse" id="sidebarCollapse">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link " href="/home" role="button" aria-expanded="true" aria-controls="sidebar-projects">
                                <i class="bi bi-house"></i>  Dashboard </a>
                        </li>

                        {/* <li class="nav-item">
                            <a class="nav-link" href="#sidebar-files" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebar-files">
                                <i class="bi bi-back"></i> Services </a>
                            <div class="collapse" id="sidebar-files">
                                <ul class="nav nav-sm flex-column">
                                    <li class="nav-item">
                                        <a href="/services?name=play&service_type=monthly_services" class="nav-link" style={{ marginLeft: '3px' }}> Plataforma Play</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="/services?name=plus&service_type=monthly_services" class="nav-link" style={{ marginLeft: '3px' }}> Plataforma Plus</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="/services?name=go&service_type=monthly_services" class="nav-link" style={{ marginLeft: '3px' }}> Plataforma Go</a>
                                    </li>
                                </ul>
                            </div>
                        </li> */}

                        {/* <li class="nav-item">
                            <a class="nav-link" href="#sidebar-files" role="button" aria-expanded="false" aria-controls="sidebar-files">
                                <i class="bi bi-back"></i> Services </a>
                        </li> */}

                      
                        {/* <li class="nav-item">
                            <a class="nav-link" href="#sidebar-authentication" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebar-authentication">
                                <i class="bi bi-cash-coin"></i> Transactions </a>
                            <div class="collapse" id="sidebar-authentication">
                                <ul class="nav nav-sm flex-column">
                                    <li class="nav-item">
                                        <a href="/apply-credit" class="nav-link">Apply credit</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="/transactions" class="nav-link">All transactions</a>
                                    </li>

                                </ul>
                            </div>
                        </li> */}

                        <li class="nav-item">
                            <a class="nav-link" href="/applications"  role="button" aria-expanded="false" aria-controls="sidebar-integrations">
                                <i class="bi bi-terminal"></i> Integrations </a>
                        </li>

                        {/* <li class="nav-item">
                            <a class="nav-link" href="#sidebar-integrations" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebar-integrations">
                                <i class="bi bi-terminal"></i> Integrations </a>
                            <div class="collapse" id="sidebar-integrations">
                                <ul class="nav nav-sm flex-column">
                                    <li class="nav-item">
                                        <a href="/applications" class="nav-link">Applications</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="/pages/integrations/manage-apps.html" class="nav-link">Manage Apps</a>
                                    </li>
                                </ul>
                            </div>
                        </li> */}

                        <li class="nav-item">
                            <a class="nav-link" href="/transactions" role="button" aria-expanded="false" aria-controls="sidebar-authentication">
                                <i class="bi bi-cash-coin"></i> Transactions </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="/users" role="button" aria-expanded="false" aria-controls="sidebar-user">
                                <i class="bi bi-people"></i> Users </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="/settings" role="button" aria-expanded="false" aria-controls="sidebar-settings">
                                <i class="bi bi-gear"></i> Settings </a>
                        </li>

                    </ul>

                    <hr class="navbar-divider my-4 opacity-70" />

                    <ul class="navbar-nav">
                        <li>
                            <span class="nav-link text-xs font-semibold text-uppercase text-muted ls-wide">Recursos</span>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link py-2" href="/transfer-service">
                                <i class="bi bi-code-square"></i> Tranfer services </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link py-2 d-flex align-items-center">
                                <i class="bi bi-journals"></i>
                                <span>Changelog</span>
                                <span class="badge badge-sm bg-soft-success text-success rounded-pill ms-auto">v1.0.0</span>
                            </a>

                        </li>
                    </ul>

                    <div class="mt-auto"></div>

                    <div class="my-4 px-lg-6 position-relative">
                        <div class="dropup w-full">
                            <button class="btn-primary d-flex w-full py-3 ps-3 pe-4 align-items-center shadow shadow-3-hover rounded-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="me-3">
                                    <img alt="..." src="https://pbs.twimg.com/profile_images/1355617557496131593/VPnL9eUy_400x400.jpg" class="avatar avatar-sm rounded-circle" />
                                </span>
                                <span class="flex-fill text-start text-sm font-semibold"> Jean </span>
                                <span>
                                    <i class="bi bi-chevron-expand text-white text-opacity-70"></i>
                                </span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end w-full">
                                <div class="dropdown-header">
                                    <span class="d-block text-sm text-muted mb-1">Signed in as</span>
                                    <span class="d-block text-heading font-semibold"> Jean </span>
                                </div>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="/home">
                                    <i class="bi bi-house me-3"></i>Dashboard </a>
                                <a class="dropdown-item" href="/services?name=play&service_type=monthly_services">
                                    <i class="bi bi-back me-3"></i>Mis servicios </a>
                                <a class="dropdown-item" href="/transfer-service">
                                    <i class="bi bi-code-square me-3"></i>Transacciones </a>
                                <div class="dropdown-divider"></div>
                                <a type="btn" class="dropdown-item" onClick={handleClick}>
                                    <i class="bi bi-box-arrow-left me-3"></i>Cerrar Sesión </a>
                            </div>
                        </div>
                        <div class="d-flex gap-3 justify-content-center align-items-center mt-6 d-none">
                            <div>
                                <i class="bi bi-moon-stars me-2 text-warning me-2"></i>
                                <span class="text-heading text-sm font-bold">Dark mode</span>
                            </div>
                            <div class="ms-auto">
                                <div class="form-check form-switch me-n2">
                                    <input class="form-check-input" type="checkbox" id="switch-dark-mode" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </nav>
    )
}
